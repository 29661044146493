.main-page {
  .wrapper {
    padding-top: 2.225rem;
    padding-bottom: 2.225rem;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 550px) {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}