@charset "UTF-8";
@import url(../../../../node_modules/magnific-popup/dist/magnific-popup.css);
@import url(../../../../node_modules/slick-carousel/slick/slick.css);
/**
 * Преобразование пикселей в rem на основе переменной с базовым размером шрифта.
 * @param  {number} $px Число пикселей (с единицами измерения или без)
 * @param  {string} Число пикселей, размер контентного шрифта на проекте
 * @return {string} Результат преобразования $px в rem
 */
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

*::before, *::after {
  box-sizing: inherit; }

html {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #524F69;
  box-sizing: border-box;
  height: 100%; }

@media screen and (max-width: 1700px) {
  html {
    font-size: 15px; } }

@media screen and (max-width: 1370px) {
  html {
    font-size: 14px; } }

@media screen and (max-width: 1100px) {
  html {
    font-size: 13px; } }

@media screen and (max-width: 340px) {
  html {
    font-size: 12px; } }

body {
  background: #F9FDFF;
  font-size: 1rem;
  line-height: normal;
  height: 100%; }
  body.off-scroll {
    overflow: hidden; }

.container {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .container > .content {
    flex: 1 0 auto;
    position: relative; }

.wrapper {
  margin: 0 auto;
  padding: 0 2.5rem;
  max-width: 1920px;
  min-width: 280px; }

.transition, .header-right .top .left .emails a, .header-right .top .left .phone a, .header-right .top .cart-block .icon-text .text, .header-right .bottom .factory, .header-right .bottom .factory.active, .footer .wrapper .emails a, .footer .wrapper .phone a, .footer .wrapper .coffee, .furniture-choise a, .top-categories .item, .product-item a h3, .product-item .price-block .cart-block .text, .cart-item .info a, .cart-item .info .count .jq-number__spin::before, .cart-item .info .count .jq-number__spin.plus::after, .product-page .wrapper .sliders-container .big-slider .slick-arrow, .product-page .wrapper .info .buy .price-block .count .jq-number__spin::before, .product-page .wrapper .info .buy .price-block .count .jq-number__spin.plus::after, .product-page .wrapper .info .buy button, .cart-page .bottom .to-catalog .text, .cart-page .bottom .total button, .in-garbage, .button-submit {
  transition: .2s; }

h1 {
  font-size: 2.1875rem;
  font-weight: 500;
  line-height: normal; }

h2 {
  font-size: 1.5rem;
  font-weight: 300; }

h3 {
  font-size: 1.4375rem;
  font-weight: 500; }

.counters {
  visibility: hidden;
  position: absolute;
  z-index: -99999; }

/* roboto-300 - latin_cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/roboto-v19-latin_cyrillic-ext-300.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Light"), local("Roboto-Light"), url("../fonts/roboto-v19-latin_cyrillic-ext-300.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v19-latin_cyrillic-ext-300.woff2") format("woff2"), url("../fonts/roboto-v19-latin_cyrillic-ext-300.woff") format("woff"), url("../fonts/roboto-v19-latin_cyrillic-ext-300.ttf") format("truetype"), url("../fonts/roboto-v19-latin_cyrillic-ext-300.svg#Roboto") format("svg");
  /* Legacy iOS */ }

/* roboto-regular - latin_cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto-v19-latin_cyrillic-ext-regular.eot");
  /* IE9 Compat Modes */
  src: local("Roboto"), local("Roboto-Regular"), url("../fonts/roboto-v19-latin_cyrillic-ext-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v19-latin_cyrillic-ext-regular.woff2") format("woff2"), url("../fonts/roboto-v19-latin_cyrillic-ext-regular.woff") format("woff"), url("../fonts/roboto-v19-latin_cyrillic-ext-regular.ttf") format("truetype"), url("../fonts/roboto-v19-latin_cyrillic-ext-regular.svg#Roboto") format("svg");
  /* Legacy iOS */ }

/* roboto-500 - latin_cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/roboto-v19-latin_cyrillic-ext-500.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Medium"), local("Roboto-Medium"), url("../fonts/roboto-v19-latin_cyrillic-ext-500.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v19-latin_cyrillic-ext-500.woff2") format("woff2"), url("../fonts/roboto-v19-latin_cyrillic-ext-500.woff") format("woff"), url("../fonts/roboto-v19-latin_cyrillic-ext-500.ttf") format("truetype"), url("../fonts/roboto-v19-latin_cyrillic-ext-500.svg#Roboto") format("svg");
  /* Legacy iOS */ }

.cookie-policy {
  position: fixed;
  z-index: 10000;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.3);
  background: #fff;
  width: 100%;
  max-width: 700px;
  bottom: 5%;
  right: 5%; }
  @media (max-width: 768px) {
    .cookie-policy {
      max-width: 500px; } }
  @media (max-width: 580px) {
    .cookie-policy {
      max-width: 100%;
      right: 0;
      bottom: 0; } }
  .cookie-policy .wrapper {
    padding-top: 20px;
    padding-bottom: 20px; }
    @media (max-width: 1000px) {
      .cookie-policy .wrapper {
        padding: 20px; } }
  .cookie-policy-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .cookie-policy-text {
    width: 100%;
    padding-right: 10px; }
    @media (max-width: 768px) {
      .cookie-policy-text {
        width: 100%;
        margin-bottom: 10px; } }
    @media (max-width: 1000px) {
      .cookie-policy-text p {
        font-size: 10px; } }
  @media (max-width: 768px) {
    .cookie-policy-button {
      align-self: flex-start; } }
  @media (max-width: 580px) {
    .cookie-policy-button {
      align-self: center; } }
  .cookie-policy-button button {
    width: 100%;
    width: 100px;
    height: 40px;
    border: 0;
    outline: 0;
    background-color: #514A8F;
    margin-top: 15px; }
    @media (max-width: 1000px) {
      .cookie-policy-button button {
        height: 30px;
        width: 80px;
        margin-top: 10px; } }
    @media (max-width: 768px) {
      .cookie-policy-button button {
        margin-top: 0; } }
    .cookie-policy-button button span {
      color: #fff; }
      @media (max-width: 1000px) {
        .cookie-policy-button button span {
          font-size: 11px; } }

.header {
  border: 1px solid rgba(0, 0, 0, 0.136); }
  .header .wrapper {
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    display: flex;
    justify-content: space-between; }
  .header-logo {
    width: 220px; }
    .header-logo-img {
      width: 100%;
      margin-bottom: 0.575rem;
      max-width: 312px; }
    .header-logo-text {
      width: 100%;
      font-weight: 300;
      font-size: 0.75rem;
      letter-spacing: 0.1em; }
  .header-right {
    width: calc(100% - 4.5rem - 320px);
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .header-right .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%; }
      .header-right .top .left {
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .header-right .top .left .emails {
          position: relative;
          align-items: center;
          margin-right: 8.625rem; }
          .header-right .top .left .emails .icon {
            position: absolute;
            left: 0;
            top: 4px;
            background-image: url(../images/sprite.png);
            background-position: -81px -25px;
            width: 19px;
            height: 19px;
            margin-right: 0.5rem; }
          .header-right .top .left .emails .row {
            padding-left: 1.875rem; }
          .header-right .top .left .emails a {
            font-size: 1.175rem;
            color: #524F69;
            text-decoration: none;
            margin-right: 2.1875rem; }
            .header-right .top .left .emails a:last-child {
              margin-right: 0; }
            .header-right .top .left .emails a:hover {
              color: #ffa2a2; }
            .header-right .top .left .emails a:active {
              color: #ff5555; }
        .header-right .top .left .phone {
          display: flex;
          align-items: center; }
          .header-right .top .left .phone .icon {
            background-image: url(../images/sprite.png);
            background-position: -81px 0px;
            width: 21px;
            height: 20px;
            margin-right: 0.5rem; }
          .header-right .top .left .phone a {
            text-decoration: none;
            font-size: 1.575rem;
            font-weight: 500;
            color: #524F69; }
            .header-right .top .left .phone a:hover {
              color: #ffa2a2; }
            .header-right .top .left .phone a:active {
              color: #ff5555; }
      .header-right .top .cart-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        padding: 0.9rem;
        border: 1px solid rgba(70, 67, 95, 0.116);
        box-sizing: border-box;
        border-radius: 5px; }
        .header-right .top .cart-block:hover .icon-text .text {
          color: #ffa2a2; }
        .header-right .top .cart-block:active .icon-text .text {
          color: #ff5555; }
        .header-right .top .cart-block .icon-text {
          display: flex;
          align-items: center; }
          .header-right .top .cart-block .icon-text .icon {
            background-image: url(../images/sprite.png);
            background-position: -30px -36px;
            width: 23px;
            height: 23px;
            margin-right: 0.75rem; }
          .header-right .top .cart-block .icon-text .text {
            font-size: 1.1rem;
            color: #BFBFBF;
            font-weight: 300; }
        .header-right .top .cart-block .price {
          font-size: 1.1875rem;
          color: #524F69;
          padding-left: 1rem; }
          .header-right .top .cart-block .price span {
            font-size: 1.6875rem;
            font-weight: 500;
            padding-right: 4px; }
    .header-right .bottom {
      display: flex;
      align-items: center;
      padding-left: 9.25rem; }
      .header-right .bottom .factory {
        font-size: 1rem;
        color: #46435F;
        text-decoration: none;
        display: block;
        margin-right: 6.25rem; }
        .header-right .bottom .factory:hover {
          color: #ffa2a2; }
        .header-right .bottom .factory:active {
          color: #ff5555; }
        .header-right .bottom .factory:last-child {
          margin-right: 0; }
        .header-right .bottom .factory.active {
          color: #ffffff;
          background: #514A8F;
          border: 2px solid #514A8F;
          padding: 0.5rem 1.1rem;
          border-radius: 5px; }
          .header-right .bottom .factory.active:hover {
            background: transparent;
            border: 2px solid #FF8888;
            color: #514A8F; }
          .header-right .bottom .factory.active:active {
            color: #ff5555; }

@media screen and (max-width: 1700px) {
  .header-logo {
    width: 220px; }
    .header-logo-text {
      font-size: .75rem; }
  .header-right {
    width: calc(100% - 320px); }
    .header-right .top .cart-block {
      padding: .8rem; }
    .header-right .top .left .phone a {
      font-size: 1.5rem;
      white-space: nowrap; }
    .header-right .bottom .factory.active {
      padding: .5rem 1.1rem; } }

@media screen and (max-width: 1470px) {
  .header-right .top .left .emails {
    margin-right: 3rem; } }

@media screen and (max-width: 1300px) {
  .header-right .top .left .emails {
    margin-right: 3rem; }
    .header-right .top .left .emails .icon {
      margin-right: 0; }
  .header-right .top .left .phone {
    margin-right: 1rem; }
  .header-right .top .cart-block .price {
    font-size: 1rem;
    max-width: 120px;
    white-space: nowrap; }
    .header-right .top .cart-block .price span {
      font-size: 1.4rem; }
  .header-right .bottom {
    padding: 0;
    justify-content: space-between; } }

@media screen and (max-width: 1100px) {
  .header-right .top .left .emails {
    margin-right: 1rem; }
    .header-right .top .left .emails a {
      margin-right: 0; }
  .header-right .top .left .phone a {
    font-size: 1.2rem; }
  .header-right .bottom .factory {
    margin-right: 0; } }

@media screen and (max-width: 970px) {
  .header-logo {
    width: 200px; }
    .header-logo-text {
      font-size: .8rem; }
  .header-right {
    width: calc(100% - 200px - 3rem); }
    .header-right .top .cart-block {
      padding: .3rem .8rem; }
      .header-right .top .cart-block .icon-text .icon {
        display: none; }
      .header-right .top .cart-block .icon-text .text {
        font-size: 1.2rem; }
      .header-right .top .cart-block .icon-text .price span {
        font-size: 1.3rem; }
    .header-right .bottom .factory.active {
      padding: .6rem 1rem; } }

@media screen and (max-width: 840px) {
  .header-right {
    width: 100%; }
    .header-right .top {
      max-width: 550px;
      margin: 1rem auto; }
    .header-right .bottom {
      justify-content: center; }
      .header-right .bottom .factory {
        margin-right: 5%; }
  .header .wrapper {
    padding-top: 1rem;
    flex-direction: column;
    align-items: center; } }

@media screen and (max-width: 610px) {
  .header-right .top {
    justify-content: center; }
    .header-right .top .left {
      flex-direction: column;
      align-items: flex-start;
      max-width: 220px; }
      .header-right .top .left .emails {
        margin-right: 0; }
      .header-right .top .left .phone {
        margin-top: .5rem; }
  .header-right .bottom {
    flex-direction: column; }
    .header-right .bottom .factory.active {
      margin: 0;
      margin-bottom: .5rem; } }

@media screen and (max-width: 430px) {
  .header-right .top {
    flex-direction: column; }
    .header-right .top .cart-block {
      margin-top: .5rem; }
    .header-right .top .left {
      max-width: 200px; } }

.footer {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.136); }
  .footer .wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    flex-wrap: wrap; }
    .footer .wrapper .logo-col {
      width: 220px; }
      .footer .wrapper .logo-col img {
        width: 100%; }
      .footer .wrapper .logo-col .text {
        font-size: .75rem;
        line-height: 1.3125rem;
        font-weight: 300;
        margin-top: .575rem;
        letter-spacing: .1em; }
    .footer .wrapper .footer-nav {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
      .footer .wrapper .footer-nav a {
        text-decoration: none;
        padding-bottom: 10px;
        font-weight: 400;
        font-size: 1.175rem;
        color: #524F69;
        transition: all .2s; }
        .footer .wrapper .footer-nav a:first-child {
          padding-left: 0; }
        .footer .wrapper .footer-nav a:hover {
          color: #ffa2a2;
          transition: all .2s; }
    .footer .wrapper .emails {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 30px;
      position: relative; }
      .footer .wrapper .emails .icon {
        position: absolute;
        top: 5px;
        left: 0;
        background-image: url(../images/sprite.png);
        background-position: -81px -25px;
        width: 19px;
        height: 19px; }
      .footer .wrapper .emails a {
        text-decoration: none;
        font-weight: 400;
        font-size: 1.175rem;
        color: #524F69;
        margin-bottom: 0.875rem; }
        .footer .wrapper .emails a:hover {
          color: #ffa2a2; }
        .footer .wrapper .emails a:active {
          color: #ff5555; }
        .footer .wrapper .emails a:last-child {
          margin-bottom: 0; }
    .footer .wrapper .phone {
      display: flex;
      align-items: center; }
      .footer .wrapper .phone .icon {
        background-image: url(../images/sprite.png);
        background-position: -81px 0px;
        width: 21px;
        height: 20px;
        margin-right: 0.875rem; }
      .footer .wrapper .phone a {
        text-decoration: none;
        font-size: 1.575rem;
        font-weight: 500;
        color: #524F69; }
        .footer .wrapper .phone a:hover {
          color: #ffa2a2; }
        .footer .wrapper .phone a:active {
          color: #ff5555; }
    .footer .wrapper .coffee {
      font-size: 0.915rem;
      color: #524F69;
      align-self: flex-end;
      width: 100%;
      text-align: right; }
      .footer .wrapper .coffee a {
        text-decoration: none;
        color: #FF8888; }
        .footer .wrapper .coffee a:hover {
          color: #ffa2a2; }
        .footer .wrapper .coffee a:active {
          color: #ff5555; }
    .footer .wrapper .container {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 75%;
      flex-direction: row; }
  .footer-bottom {
    text-align: center;
    width: 100%;
    margin-top: 10px; }
    .footer-bottom span {
      font-size: 10px;
      color: #BFBFBF; }
      .footer-bottom span a {
        text-decoration: none;
        color: #918eaa;
        transition: all .2s; }
        .footer-bottom span a:hover {
          color: #524f69;
          transition: all .2s; }

@media screen and (max-width: 1200px) {
  .footer .wrapper {
    padding: 1rem 2rem;
    flex-wrap: wrap; }
    .footer .wrapper .coffee {
      width: 100%;
      text-align: end;
      padding-top: 2rem; }
    .footer .wrapper .container {
      width: calc(100% - 240px); } }

@media screen and (max-width: 800px) {
  .footer .wrapper .container .footer-nav {
    justify-content: space-around; }
  .footer .wrapper .phone a {
    font-size: 1.4rem; } }

@media screen and (max-width: 740px) {
  .footer .wrapper .container {
    width: calc(100% - 240px);
    flex-direction: column;
    align-items: center; }
  .footer .wrapper .phone {
    margin-top: 1rem; } }

@media screen and (max-width: 530px) {
  .footer .wrapper {
    flex-direction: column;
    align-items: center; }
    .footer .wrapper .logo-col .text {
      text-align: center;
      margin-top: .5rem; }
    .footer .wrapper .container {
      width: auto;
      align-items: center;
      padding: 1rem 0; }
    .footer .wrapper .coffee {
      align-self: center;
      width: auto;
      margin: 0;
      padding-top: .5rem;
      font-size: 0.87rem; } }

.furniture-choise {
  background: #E9EFF7; }
  .furniture-choise .wrapper {
    padding: 0.375rem 1.875rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
  .furniture-choise a {
    font-size: 1.1875rem;
    font-weight: 500;
    color: #444159;
    display: block;
    text-decoration: none;
    padding: .8rem 2rem;
    border: 2px solid transparent;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 0.625rem;
    white-space: nowrap; }
    .furniture-choise a:hover {
      border-color: #FF8888;
      color: #514A8F; }
    .furniture-choise a.active {
      border-color: #FF8888;
      color: #514A8F; }

@media screen and (max-width: 1300px) {
  .furniture-choise .wrapper {
    justify-content: space-between; }
  .furniture-choise a {
    font-size: 1.1875rem;
    padding: .8rem 2rem; } }

@media screen and (max-width: 940px) {
  .furniture-choise .wrapper {
    justify-content: center; }
  .furniture-choise a {
    margin: 4px; } }

.top-categories {
  display: flex;
  padding: 0.6rem 0;
  transition: none;
  max-width: 1920px;
  min-width: 280px;
  margin: 0 auto; }
  .top-categories-container {
    border: 1px solid rgba(0, 0, 0, 0.136); }
  .top-categories .item {
    margin-left: 5px;
    padding: .6rem 2.575rem;
    border: 2px solid #FF8888;
    border-radius: 5px;
    text-decoration: none;
    color: #524F69;
    font-size: .9rem;
    line-height: 2rem;
    font-weight: 500; }
    .top-categories .item:hover {
      color: #fff;
      background-color: #FF8888;
      border-color: #FF8888; }
    .top-categories .item:last-child {
      margin-right: 5px; }
  .top-categories-menu-hide {
    display: none; }
  .top-categories-menu {
    justify-content: flex-end;
    align-items: center;
    padding: 0 1rem;
    display: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.136); }
    .top-categories-menu__button {
      display: flex;
      align-items: center;
      cursor: pointer; }
      .top-categories-menu__button svg {
        width: 20px;
        height: 20px;
        padding: 1rem;
        fill: #514A8F; }
      .top-categories-menu__button p {
        line-height: 20px;
        font-size: 20px;
        text-transform: uppercase;
        color: #514A8F; }
      .top-categories-menu__button:hover svg {
        fill: #FF8888; }
      .top-categories-menu__button:hover p {
        color: #FF8888; }
    .top-categories-menu .active {
      display: flex; }
      .top-categories-menu .active svg {
        transition: .3s ease-in;
        fill: #FF8888; }
        .top-categories-menu .active svg rect:nth-child(3) {
          transform: translateY(100px); }
        .top-categories-menu .active svg rect:nth-child(4) {
          transform: translateX(-100px); }
        .top-categories-menu .active svg rect:nth-child(7) {
          transform: translateY(-100px); }
        .top-categories-menu .active svg rect:nth-child(8) {
          transform: translateX(100px); }
      .top-categories-menu .active p {
        color: #FF8888; }
      .top-categories-menu .active:hover svg {
        fill: #514A8F; }
      .top-categories-menu .active:hover p {
        color: #514A8F; }

@media screen and (max-width: 1500px) {
  .top-categories .item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 16.66667%;
    flex-grow: 1;
    flex-shrink: 1;
    font-size: 1rem;
    padding: .6rem 0.875rem;
    line-height: 1.7;
    text-align: center; } }

@media screen and (max-width: 840px) {
  .top-categories {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border-top: none; }
    .top-categories .item {
      width: 100%;
      margin-left: 0;
      text-align: center;
      line-height: 1.5;
      margin-bottom: .5rem; }
      .top-categories .item:last-child {
        margin-right: 0;
        margin-bottom: 0; }
    .top-categories-menu {
      display: flex; }
    .top-categories-container {
      transition: none;
      display: none; } }

.product-item {
  width: calc(25% - 15px);
  margin-right: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  padding: 1.75rem;
  padding-bottom: 4.8em;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  .product-item.sale .sale-size {
    display: block; }
  .product-item.sale .price-block .price .old-price {
    display: block; }
  .product-item .sale-size {
    position: absolute;
    right: 30px;
    top: 125px;
    width: 72px;
    height: 72px;
    background: rgba(122, 211, 255, 0.706);
    border-radius: 50%;
    color: #ffffff;
    text-align: center;
    line-height: 72px;
    font-size: 1.6875rem;
    font-weight: 500;
    display: none; }
  .product-item:nth-child(4n) {
    margin-right: 0; }
  .product-item a {
    text-decoration: none;
    color: #524F69;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .product-item a:hover h3 {
      display: block;
      color: #ffa2a2; }
    .product-item a:active h3 {
      color: #ff5555; }
    .product-item a h3 {
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 2rem;
      max-height: 96px;
      overflow: hidden; }
    .product-item a .img {
      width: 100%;
      height: auto; }
      .product-item a .img img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        margin: auto; }
  .product-item .price-block {
    width: 87%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    padding-bottom: 1.75rem;
    bottom: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    .product-item .price-block .price {
      font-size: 1.5rem;
      position: relative;
      top: -3px; }
      .product-item .price-block .price .old-price {
        position: absolute;
        left: 0;
        bottom: 100%;
        width: 100%;
        text-align: center;
        font-size: 1.4375rem;
        color: #7A7A7A;
        display: none; }
        .product-item .price-block .price .old-price::after {
          content: '';
          width: 95%;
          height: 2px;
          background: #313131;
          position: absolute;
          top: calc(50% - 5px);
          left: 50%;
          transform: rotate(-4.24deg) translateX(-50%); }
      .product-item .price-block .price span {
        font-weight: 500;
        font-size: 1.875rem; }
    .product-item .price-block .cart-block {
      background: transparent;
      border: 1px solid rgba(70, 67, 95, 0.116);
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.75rem 0.875rem 0.75rem 1.25rem;
      cursor: pointer; }
      .product-item .price-block .cart-block:hover .text {
        color: #ffa2a2; }
      .product-item .price-block .cart-block:active .text {
        color: #ff5555; }
      .product-item .price-block .cart-block .text {
        font-size: 1.25rem;
        font-weight: 30;
        color: #46435F; }
      .product-item .price-block .cart-block .icon {
        background-image: url(../images/sprite.png);
        background-position: -30px -36px;
        width: 23px;
        height: 23px;
        margin-left: 0.875rem; }
  .product-item .slider-wrapper {
    position: relative;
    width: 100%;
    padding: 40px 0 66px;
    margin: 8px 0 0; }
    @media (max-width: 768px) {
      .product-item .slider-wrapper {
        padding: 30px 0 46px; } }
    .product-item .slider-wrapper .slide-content-top {
      position: absolute;
      padding: 8px 45px;
      background-color: #ff6316;
      color: #fff;
      font-size: 17px;
      top: 0;
      right: 0;
      border-radius: 0 0 0 15px; }
      @media (max-width: 768px) {
        .product-item .slider-wrapper .slide-content-top {
          font-size: 13px;
          padding: 5px 25px; } }
    .product-item .slider-wrapper .slide-content-bottom {
      position: absolute;
      padding: 8px 5px;
      font-size: 17px;
      width: 100%;
      max-width: 269px;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #ff6316;
      color: #fff;
      text-align: center;
      z-index: 20; }
      @media (max-width: 768px) {
        .product-item .slider-wrapper .slide-content-bottom {
          font-size: 13px;
          padding: 5px 5px; } }
  .product-item-slider {
    display: block;
    width: 100%;
    max-width: calc(100% - 62px);
    margin: 0 auto;
    align-self: flex-end; }
    .product-item-slider .img {
      height: 300px;
      display: flex !important;
      align-items: center;
      justify-content: center; }
      @media (max-width: 768px) {
        .product-item-slider .img {
          height: 250px; } }
      .product-item-slider .img img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        margin: auto; }
    .product-item-slider .slick-arrow {
      background: url(../images/sprite.png);
      border: 0;
      background-size: 72px 46px;
      font-size: 0;
      text-indent: -9999px;
      z-index: 1;
      position: absolute;
      top: calc(50% - 13px);
      width: 27px;
      height: 27px;
      opacity: .5; }
      .product-item-slider .slick-arrow.slick-prev {
        transform: rotate(180deg);
        left: -35px;
        top: calc(50% - 17px); }
      .product-item-slider .slick-arrow.slick-next {
        right: -35px; }

.nav-slider .slide-content-top,
.nav-slider .slide-content-bottom {
  display: none; }
  @media (max-width: 1040px) {
    .nav-slider .slide-content-top,
    .nav-slider .slide-content-bottom {
      display: block; } }

@media screen and (max-width: 1540px) {
  .product-item a h3 {
    font-size: 1.2rem; }
  .product-item .price-block .price {
    font-size: 1.2rem;
    top: -1px; }
    .product-item .price-block .price span {
      font-size: 1.5rem; } }

@media screen and (max-width: 1416px) {
  .product-item {
    width: calc(33% - 15px); }
    .product-item:nth-child(4n) {
      margin-right: 20px; }
    .product-item:nth-child(3n) {
      margin-right: 0; } }

@media screen and (max-width: 1085px) {
  .product-item {
    width: calc(50% - 15px); }
    .product-item:nth-child(3n) {
      margin-right: 20px; }
    .product-item:nth-child(2n) {
      margin-right: 0; } }

@media screen and (max-width: 700px) {
  .wrapper {
    padding: 0 1rem; }
  .product-item {
    width: 49%;
    margin-right: 2%; }
    .product-item:nth-child(3n) {
      margin-right: 2%; }
    .product-item .price-block .cart-block {
      padding: 0.3rem 0.5rem 0.3rem 0.5rem; }
    .product-item .price-block .price {
      font-size: 1rem; }
      .product-item .price-block .price span {
        font-size: 1.4rem; } }

@media screen and (max-width: 550px) {
  .product-item {
    padding: 8px 8px 70px 8px;
    margin-bottom: 8px; }
    .product-item:nth-child(3n) {
      margin-right: 2%; }
    .product-item:nth-child(2n) {
      margin-right: 0; }
    .product-item a h3 {
      font-size: 14px;
      line-height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -moz-box;
      -moz-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      box-orient: vertical; }
    .product-item-slider {
      max-width: calc(100% - 24px); }
      .product-item-slider .img {
        height: 140px; }
      .product-item-slider .slick-arrow.slick-prev {
        transform: rotate(180deg) scale(0.75);
        left: -20px; }
      .product-item-slider .slick-arrow.slick-next {
        transform: scale(0.75);
        right: -20px; }
    .product-item .price-block {
      display: block;
      padding-bottom: 8px;
      width: calc(100% - 16px); }
      .product-item .price-block .cart-block {
        width: 100%;
        margin: 4px 0 0; } }

@media screen and (max-width: 430px) {
  .product-item-slider .img {
    height: 100px; }
    .product-item-slider .img a {
      width: 100%;
      height: 100%; } }

.cart-item {
  padding-left: 3.875rem;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  position: relative;
  margin-bottom: 1.5rem; }
  .cart-item .img {
    width: 260px;
    margin-right: 6.875rem; }
    .cart-item .img img {
      width: 100%; }
  .cart-item .info {
    display: flex;
    align-items: flex-start; }
    .cart-item .info a {
      padding-right: 4rem;
      display: block;
      width: 450px;
      margin-right: 8.4375rem;
      font-size: 1.6rem;
      line-height: 2.875rem;
      font-weight: 500;
      color: #524F69;
      text-decoration: none; }
      .cart-item .info a:hover {
        color: #ffa2a2; }
      .cart-item .info a:active {
        color: #ff5555; }
    .cart-item .info .title {
      font-size: 1.375rem;
      color: #BFBFBF;
      margin-bottom: 1.25rem; }
    .cart-item .info .count {
      margin-right: 15rem; }
      .cart-item .info .count .jq-number {
        width: 132px;
        height: 36px;
        position: relative;
        padding: 0 36px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.062);
        margin-top: 1rem; }
        .cart-item .info .count .jq-number__field {
          width: 100%;
          height: 100%;
          background: #ffffff; }
          .cart-item .info .count .jq-number__field input {
            width: 100%;
            height: 100%;
            display: block;
            text-align: center;
            border: 0;
            padding: 0;
            background: #ffffff;
            font-size: 1.3125rem;
            color: #636363; }
            .cart-item .info .count .jq-number__field input::-webkit-outer-spin-button, .cart-item .info .count .jq-number__field input::-webkit-inner-spin-button {
              -webkit-appearance: none; }
        .cart-item .info .count .jq-number__spin {
          width: 36px;
          height: 36px;
          background: #ffffff;
          position: absolute;
          top: 0;
          cursor: pointer; }
          .cart-item .info .count .jq-number__spin:hover::before {
            opacity: 1; }
          .cart-item .info .count .jq-number__spin::before {
            content: '';
            width: 14px;
            height: 1px;
            background: #000000;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: .7; }
          .cart-item .info .count .jq-number__spin.plus {
            right: 0; }
            .cart-item .info .count .jq-number__spin.plus:hover::after {
              opacity: 1; }
            .cart-item .info .count .jq-number__spin.plus::after {
              content: '';
              height: 14px;
              width: 1px;
              background: #000000;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              opacity: .7; }
          .cart-item .info .count .jq-number__spin.minus {
            left: 0; }
    .cart-item .info .price-block .price {
      margin-top: 1rem;
      font-size: 1.5rem;
      color: #FF8888; }
      .cart-item .info .price-block .price span {
        font-weight: 500;
        font-size: 2.225rem;
        padding-right: 5px; }
  .cart-item .delete {
    position: absolute;
    top: 3rem;
    right: 2.25rem;
    background: transparent;
    border: 0;
    cursor: pointer;
    background-image: url(../images/sprite.png);
    background-position: 0px -36px;
    width: 25px;
    height: 25px; }

.aside-menu {
  min-width: 368px;
  box-sizing: border-box;
  margin-right: 43px; }
  @media (max-width: 1400px) {
    .aside-menu {
      margin-right: 43px;
      width: 288px;
      min-width: auto; } }
  @media (max-width: 1000px) {
    .aside-menu {
      margin-right: 0px;
      width: 100%; } }
  @media (max-width: 768px) {
    .aside-menu {
      width: 100%; } }
  .aside-menu nav {
    width: 100%; }
    .aside-menu nav ul {
      box-sizing: border-box;
      border: 1px solid #E5E5E5; }
      @media (max-width: 1000px) {
        .aside-menu nav ul {
          display: flex; } }
      @media (max-width: 480px) {
        .aside-menu nav ul {
          flex-direction: column; } }
      .aside-menu nav ul .active-tab {
        background-color: #514A8F;
        color: #fff; }
        .aside-menu nav ul .active-tab a {
          color: #fff; }
      .aside-menu nav ul li {
        box-sizing: border-box;
        border-bottom: 1px solid rgba(147, 147, 147, 0.33); }
        @media (max-width: 1000px) {
          .aside-menu nav ul li {
            width: 30%;
            text-align: center; }
            .aside-menu nav ul li:first-child {
              width: 20%; }
            .aside-menu nav ul li:nth-child(3) {
              width: 20%; }
            .aside-menu nav ul li:last-child {
              width: 30%; } }
        @media (max-width: 768px) {
          .aside-menu nav ul li {
            width: 25%; }
            .aside-menu nav ul li:first-child {
              width: 15%; }
            .aside-menu nav ul li:nth-child(3) {
              width: 18%; }
            .aside-menu nav ul li:last-child {
              width: 43%; } }
        @media (max-width: 480px) {
          .aside-menu nav ul li {
            width: 100%;
            text-align: center; }
            .aside-menu nav ul li:first-child {
              width: 100%; }
            .aside-menu nav ul li:nth-child(3) {
              width: 100%; }
            .aside-menu nav ul li:last-child {
              width: 100%; } }
        .aside-menu nav ul li a {
          display: block;
          font-size: 1.25rem;
          line-height: 4.375rem;
          color: #333333;
          font-weight: 500;
          text-decoration: none;
          padding-left: 27px;
          transition: all .2s; }
          @media (max-width: 1400px) {
            .aside-menu nav ul li a {
              font-size: 1rem;
              line-height: 3.75rem;
              padding-left: 23px; } }
          @media (max-width: 1000px) {
            .aside-menu nav ul li a {
              font-size: 0.875rem;
              line-height: 3.125rem;
              padding-left: 18px; } }
          @media (max-width: 768px) {
            .aside-menu nav ul li a {
              font-size: 0.875rem;
              line-height: 3.125rem;
              padding-left: 0px; } }
          .aside-menu nav ul li a:hover {
            background-color: #514A8F;
            color: #fff;
            transition: all .2s; }

.approve {
  position: relative;
  display: block;
  margin-bottom: 25px;
  padding: 20px;
  padding-left: 80px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.086);
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #4F4F4F; }

@media screen and (max-width: 460px) {
  .approve {
    padding-left: 20px;
    padding-top: 70px;
    text-align: center; } }
  .approve::before {
    content: '';
    position: absolute;
    top: 25px;
    left: 20px;
    width: 40px;
    height: 40px;
    border: 1px solid #32BA7C;
    border-radius: 50%;
    background: url("../images/approve.svg") center no-repeat;
    background-size: 65%; }

@media screen and (max-width: 460px) {
  .approve::before {
    top: 20px;
    left: calc(50% - 20px);
    width: 40px;
    height: 40px; } }
  .approve__header {
    font-size: 20px;
    color: #32BA7C; }

@media screen and (max-width: 800px) {
  .approve__header {
    font-size: 18px; } }

@media screen and (max-width: 600px) {
  .approve__header {
    font-size: 16px; } }

@media screen and (max-width: 460px) {
  .approve__header {
    margin-bottom: 10px; } }
  .approve__text {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 400; }
    .approve__text span {
      display: inline-block;
      margin-bottom: 20px; }
      .approve__text span:last-child {
        margin-bottom: 0; }
    .approve__text--bold {
      font-size: 18px;
      font-weight: 600; }

@media screen and (max-width: 800px) {
  .approve__text--bold {
    font-size: 16px; } }

@media screen and (max-width: 600px) {
  .approve__text--bold {
    font-size: 14px; } }

@media screen and (max-width: 800px) {
  .approve__text {
    font-size: 14px; } }

@media screen and (max-width: 460px) {
  .approve__text {
    padding: 0 20px; } }

@media screen and (max-width: 350px) {
  .approve__text {
    padding: 0; } }

.main-page .wrapper {
  padding-top: 2.225rem;
  padding-bottom: 2.225rem;
  display: flex;
  flex-wrap: wrap; }
  @media screen and (max-width: 550px) {
    .main-page .wrapper {
      padding-left: 8px;
      padding-right: 8px; } }

.product-page .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: 1.625rem;
  padding-bottom: 3.75rem; }
  .product-page .wrapper h1 {
    width: 100%;
    text-align: center;
    margin-bottom: 2.5625rem; }
  .product-page .wrapper .sliders-container {
    width: 40%;
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    padding-bottom: 1.25rem; }
    @media (max-width: 1040px) {
      .product-page .wrapper .sliders-container {
        position: relative;
        padding-bottom: 3rem; } }
    .product-page .wrapper .sliders-container .big-slider-wrapper {
      position: relative;
      padding: 2.5rem 0 55px;
      margin: 0; }
      @media (max-width: 1040px) {
        .product-page .wrapper .sliders-container .big-slider-wrapper {
          position: static;
          padding: 0; } }
      .product-page .wrapper .sliders-container .big-slider-wrapper .slide-content-top {
        position: absolute;
        padding: 8px 45px;
        background-color: #ff6316;
        color: #fff;
        font-size: 19px;
        top: 0;
        right: 0;
        border-radius: 0 0 0 15px;
        z-index: 20; }
        @media (max-width: 1024px) {
          .product-page .wrapper .sliders-container .big-slider-wrapper .slide-content-top {
            padding: 5px 30px;
            font-size: 15px; } }
        @media (max-width: 375px) {
          .product-page .wrapper .sliders-container .big-slider-wrapper .slide-content-top {
            font-size: 13px; } }
      .product-page .wrapper .sliders-container .big-slider-wrapper .slide-content-bottom {
        position: absolute;
        padding: 8px 20px;
        font-size: 19px;
        bottom: 0;
        width: 100%;
        max-width: 380px;
        background-color: #ff6316;
        color: #fff;
        text-align: center;
        z-index: 20;
        left: 50%;
        transform: translateX(-50%); }
        @media (max-width: 1040px) {
          .product-page .wrapper .sliders-container .big-slider-wrapper .slide-content-bottom {
            max-width: 612px; } }
        @media (max-width: 1024px) {
          .product-page .wrapper .sliders-container .big-slider-wrapper .slide-content-bottom {
            padding: 5px 20px;
            font-size: 15px; } }
        @media (max-width: 375px) {
          .product-page .wrapper .sliders-container .big-slider-wrapper .slide-content-bottom {
            font-size: 13px;
            padding: 5px 10px; } }
    .product-page .wrapper .sliders-container .big-slider {
      width: 100%;
      padding: 3.5rem 8.875rem 0; }
      .product-page .wrapper .sliders-container .big-slider .slick-arrow {
        background: transparent;
        border: 0;
        font-size: 0;
        text-indent: -9999px;
        z-index: 1;
        position: absolute;
        top: calc(50% - 15px);
        background-image: url(../images/sprite.png);
        background-position: 0px 0px;
        width: 38px;
        height: 31px;
        opacity: 0.5; }
        .product-page .wrapper .sliders-container .big-slider .slick-arrow:hover {
          opacity: 1; }
        .product-page .wrapper .sliders-container .big-slider .slick-arrow.slick-prev {
          left: 40px;
          transform: rotate(180deg); }
        .product-page .wrapper .sliders-container .big-slider .slick-arrow.slick-next {
          right: 40px; }
    .product-page .wrapper .sliders-container .nav-slider {
      width: 100%;
      padding: 2.5rem 7.875rem 0; }
      .product-page .wrapper .sliders-container .nav-slider .slick-slide {
        padding: 0 5px; }
        .product-page .wrapper .sliders-container .nav-slider .slick-slide:has(div .slide-content-bottom), .product-page .wrapper .sliders-container .nav-slider .slick-slide:has(div .slide-content-top) {
          display: none; }
        .product-page .wrapper .sliders-container .nav-slider .slick-slide .slide {
          border: 1px solid transparent; }
        .product-page .wrapper .sliders-container .nav-slider .slick-slide.slick-current .slide {
          border-color: #FF8888; }
    .product-page .wrapper .sliders-container .slide img {
      width: 100%;
      height: auto; }
  .product-page .wrapper .info {
    width: calc(60% - 4.875rem); }
    .product-page .wrapper .info .modules-price, .product-page .wrapper .info .kit-price {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      border-bottom: 1px solid rgba(0, 0, 0, 0.108); }
    .product-page .wrapper .info .title {
      color: #BFBFBF;
      font-size: 1.375rem;
      margin-top: 15px;
      margin-right: 5px; }
    .product-page .wrapper .info .modules-price {
      margin-bottom: 2rem; }
      .product-page .wrapper .info .modules-price .price {
        font-size: 1.2625rem;
        color: #524F69; }
        .product-page .wrapper .info .modules-price .price span {
          font-weight: 500;
          font-size: 1.8125rem; }
    .product-page .wrapper .info .kit-price {
      margin-bottom: 1.5rem; }
      .product-page .wrapper .info .kit-price .price {
        font-size: 1.3625rem;
        color: #FF8888; }
        .product-page .wrapper .info .kit-price .price span {
          font-weight: 500;
          font-size: 2.225rem; }
    .product-page .wrapper .info .description {
      padding-bottom: 3.25rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.108); }
      .product-page .wrapper .info .description .title {
        width: 100%; }
      .product-page .wrapper .info .description p {
        font-size: 1.375rem;
        color: #4f4f4f;
        margin-top: 0.5rem; }
      .product-page .wrapper .info .description strong {
        font-weight: 700; }
      .product-page .wrapper .info .description em {
        font-style: italic; }
    .product-page .wrapper .info .buy {
      width: 100%;
      padding-top: 3rem; }
      .product-page .wrapper .info .buy form {
        display: flex;
        justify-content: space-between;
        align-items: center; }
      .product-page .wrapper .info .buy .price-block {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start; }
        .product-page .wrapper .info .buy .price-block .count .jq-number {
          width: 132px;
          height: 36px;
          position: relative;
          padding: 0 36px;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.062);
          margin-top: 1rem; }
          .product-page .wrapper .info .buy .price-block .count .jq-number__field {
            width: 100%;
            height: 100%;
            background: #ffffff; }
            .product-page .wrapper .info .buy .price-block .count .jq-number__field input {
              width: 100%;
              height: 100%;
              display: block;
              text-align: center;
              border: 0;
              padding: 0;
              background: #ffffff;
              font-size: 1.3125rem;
              color: #636363; }
              .product-page .wrapper .info .buy .price-block .count .jq-number__field input::-webkit-outer-spin-button, .product-page .wrapper .info .buy .price-block .count .jq-number__field input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                -moz-appearance: none;
                margin: 0; }
            .product-page .wrapper .info .buy .price-block .count .jq-number__field input[type="number"] {
              -moz-appearance: textfield; }
            .product-page .wrapper .info .buy .price-block .count .jq-number__field input[type="number"]:hover,
            .product-page .wrapper .info .buy .price-block .count .jq-number__field input[type="number"]:focus {
              -moz-appearance: textfield; }
          .product-page .wrapper .info .buy .price-block .count .jq-number__spin {
            width: 36px;
            height: 36px;
            background: #ffffff;
            position: absolute;
            top: 0;
            cursor: pointer; }
            .product-page .wrapper .info .buy .price-block .count .jq-number__spin:hover::before {
              opacity: 1; }
            .product-page .wrapper .info .buy .price-block .count .jq-number__spin::before {
              content: '';
              width: 14px;
              height: 1px;
              background: #000000;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              opacity: .7; }
            .product-page .wrapper .info .buy .price-block .count .jq-number__spin.plus {
              right: 0; }
              .product-page .wrapper .info .buy .price-block .count .jq-number__spin.plus:hover::after {
                opacity: 1; }
              .product-page .wrapper .info .buy .price-block .count .jq-number__spin.plus::after {
                content: '';
                height: 14px;
                width: 1px;
                background: #000000;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: .7; }
            .product-page .wrapper .info .buy .price-block .count .jq-number__spin.minus {
              left: 0; }
        .product-page .wrapper .info .buy .price-block .price-block {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 4.5rem; }
          .product-page .wrapper .info .buy .price-block .price-block .price {
            font-size: 1.375rem;
            color: #BFBFBF;
            margin-top: 1.25rem; }
      .product-page .wrapper .info .buy button {
        font-size: 1rem;
        color: #ffffff;
        font-weight: 500;
        background: #514A8F;
        border-radius: 5px;
        border: 2px solid transparent;
        padding: .8rem 2rem; }
        .product-page .wrapper .info .buy button:hover {
          color: #514A8F;
          border-color: #ffa2a2;
          background: transparent; }
        .product-page .wrapper .info .buy button:active {
          color: #ff5555; }

@media screen and (max-width: 1300px) {
  .product-page .wrapper .sliders-container {
    width: 50%; }
  .product-page .wrapper .info {
    width: calc(50% - 4.875rem); }
    .product-page .wrapper .info .description {
      padding-bottom: 2rem; }
      .product-page .wrapper .info .description p {
        font-size: 1rem; }
    .product-page .wrapper .info .buy {
      padding-top: 1rem; }
      .product-page .wrapper .info .buy button {
        font-size: 1.2rem;
        padding: 1rem 2rem; }
      .product-page .wrapper .info .buy .price-block .price-block {
        margin-left: 1rem; }
    .product-page .wrapper .info .kit-price .price span {
      font-size: 2rem; }
    .product-page .wrapper .info .modules-price {
      margin-bottom: 1rem; }
      .product-page .wrapper .info .modules-price .price span {
        font-size: 2rem; } }

@media screen and (max-width: 1220px) {
  .product-page .wrapper .info .modules-price {
    display: flex;
    flex-direction: column;
    margin-bottom: 0; }
  .product-page .wrapper .info .kit-price {
    display: flex;
    flex-direction: column; } }

@media screen and (max-width: 1040px) {
  .product-page .wrapper {
    flex-direction: column; }
    .product-page .wrapper .sliders-container {
      width: 100%; }
      .product-page .wrapper .sliders-container .big-slider {
        display: none; }
      .product-page .wrapper .sliders-container .nav-slider {
        padding: 0 2rem 0 2rem; } }
      @media screen and (max-width: 1040px) and (max-width: 1024px) {
        .product-page .wrapper .sliders-container .nav-slider {
          padding-top: 40px; } }

@media screen and (max-width: 1040px) {
    .product-page .wrapper .info {
      width: 100%; } }

@media screen and (max-width: 460px) {
  .product-page .wrapper {
    padding-bottom: 1.75rem; }
    .product-page .wrapper .info .buy form {
      flex-wrap: wrap; }
    .product-page .wrapper .info .buy .price-block {
      width: 100%; }
    .product-page .wrapper .info .buy button {
      margin-left: auto;
      margin-top: 1rem; }
    .product-page .wrapper .info .buy .price-block .price-block {
      margin-left: 10%; } }

@media screen and (max-width: 370px) {
  .product-page .wrapper .info .buy button {
    margin: 0 auto;
    margin-top: 2rem; } }

.scheme {
  display: none; }

.cart-page .wrapper {
  padding-top: 1.525rem;
  padding-bottom: 2.5rem; }

.cart-page h1 {
  text-align: left;
  margin-bottom: 1.5rem; }

.cart-page .bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }
  .cart-page .bottom .to-catalog {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-top: 1.875rem; }
    .cart-page .bottom .to-catalog:hover .text {
      color: #ffa2a2; }
    .cart-page .bottom .to-catalog:active .text {
      color: #ff5555; }
    .cart-page .bottom .to-catalog .icon {
      background-image: url(../images/sprite.png);
      background-position: -43px 0px;
      width: 33px;
      height: 27px;
      margin-right: 2.25rem; }
    .cart-page .bottom .to-catalog .text {
      font-size: 1.9rem;
      font-weight: 500;
      color: #514A8F; }
  .cart-page .bottom .total {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    .cart-page .bottom .total .item {
      display: flex;
      align-items: flex-end;
      line-height: 2rem;
      font-size: 1.375rem; }
      .cart-page .bottom .total .item .name {
        margin-right: 0.5rem; }
    .cart-page .bottom .total .total-summ {
      margin-top: 1.25rem;
      display: flex;
      align-items: flex-end; }
      .cart-page .bottom .total .total-summ .text {
        font-size: 1.5rem;
        font-weight: 500;
        color: #524F69;
        margin-right: 1.5rem; }
      .cart-page .bottom .total .total-summ .price {
        font-size: 1.2625rem;
        color: #FF8888; }
        .cart-page .bottom .total .total-summ .price span {
          font-size: 2.1rem;
          font-weight: 500;
          line-height: 2rem; }
    .cart-page .bottom .total button {
      font-size: 1.25rem;
      font-weight: 500;
      padding: 0.9rem 1.8rem;
      cursor: pointer;
      color: #ffffff;
      background: #514A8F;
      border: 2px solid transparent;
      border-radius: 5px;
      margin-top: 1.5rem; }
      .cart-page .bottom .total button:hover {
        background: transparent;
        border-color: #ffa2a2;
        color: #514A8F; }
      .cart-page .bottom .total button:active {
        color: #ff5555; }

.cart-item .info .count .jq-number__field input {
  appearance: textfield; }

@media screen and (max-width: 1740px) {
  .cart-item {
    padding: 0 2%; }
    .cart-item .img {
      margin-right: 0; }
    .cart-item .info {
      width: calc(97% - 260px);
      margin-left: 3%; }
      .cart-item .info .count {
        margin: 0 auto;
        padding: 0 1rem; }
      .cart-item .info a {
        font-size: 1.7rem;
        line-height: 1.7;
        margin-right: 0; } }

@media screen and (max-width: 1300px) {
  .cart-item .info a {
    font-size: 1.5rem; }
  .cart-item .info .price-block .price span {
    font-size: 2rem; } }

@media screen and (max-width: 1180px) {
  .cart-item .info {
    flex-wrap: wrap;
    margin: 5% 0;
    margin-left: 5%; }
    .cart-item .info .count {
      margin: 0;
      padding-left: 0; }
    .cart-item .info a {
      width: 100%;
      margin-bottom: 1rem; } }

@media screen and (max-width: 800px) {
  .cart-item .img {
    width: 170px; }
  .cart-item .delete {
    top: 1rem;
    right: 1rem;
    transform: scale(0.5); }
  .cart-item .info {
    width: calc(97% - 215px); }
    .cart-item .info a {
      font-size: 1.4rem; }
    .cart-item .info .title {
      font-size: 1.1rem;
      margin-bottom: .5rem; }
    .cart-item .info .count .jq-number {
      width: 105px;
      margin-top: .5rem; } }

@media screen and (max-width: 640px) {
  .cart-page .bottom .to-catalog .text {
    font-size: 1.5rem; }
  .cart-page .bottom .total button {
    font-size: 1.4rem;
    padding: .9rem 2rem; }
  .cart-page .bottom .total .total-summ {
    margin-top: .8rem; }
    .cart-page .bottom .total .total-summ .text {
      font-size: 1.8rem; }
    .cart-page .bottom .total .total-summ .price span {
      font-size: 2rem; }
  .cart-item .img {
    width: 115px; }
  .cart-item .info {
    width: calc(97% - 120px);
    margin-top: 2rem; }
  .cart-item .delete {
    top: .4rem; } }

@media screen and (max-width: 540px) {
  .cart-page .bottom {
    flex-direction: column; }
    .cart-page .bottom .total {
      width: 100%;
      align-items: flex-start;
      margin-top: 2rem; }
      .cart-page .bottom .total button {
        margin: 0 auto;
        margin-top: 1.8rem; }
  .cart-item {
    flex-direction: column; }
    .cart-item .info {
      width: 100%;
      margin-top: 0; } }

.delivery .wrapper {
  overflow-x: hidden; }

.delivery .container {
  position: relative;
  overflow: hidden; }

.delivery h2 {
  font-weight: 500;
  font-size: 2.1875rem;
  margin-top: 1.5625rem; }

.delivery h3 {
  font-size: 1.5625rem; }

.delivery-form {
  width: 100%;
  max-width: 1839px;
  margin: 0 auto;
  margin-bottom: 4.25rem;
  margin-top: 1.5625rem;
  padding: 4rem 4.6875rem 3.6875rem 4.6875rem;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25); }
  .delivery-form__tabs {
    display: flex;
    margin-bottom: 7.1875rem; }
    .delivery-form__tabs .radio:not(:last-child) {
      margin-right: 22.26169%; }
  .delivery-form__main {
    display: flex;
    justify-content: space-between; }
  .delivery-form__price {
    display: flex;
    margin-top: 6.375rem;
    align-items: center;
    justify-content: space-between; }
  .delivery-form__submit {
    display: flex;
    justify-content: flex-end; }

.column {
  width: 31%; }

.contact-details .checkbox {
  display: block;
  margin-top: 6.75rem; }

.tin p {
  margin-top: 17.8125rem;
  font-size: 1.25rem;
  color: #524F69; }

.delivery-city .checkbox {
  display: block;
  margin-top: 2.8125rem; }

.delivery-address {
  margin-top: 4.4375rem; }

.in-garbage {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.875rem;
  line-height: normal;
  color: #524F69; }
  .in-garbage__text {
    margin-left: 22px; }
  .in-garbage svg {
    width: 60px;
    height: 60px; }
  .in-garbage:hover {
    color: #ffa2a2; }

.price-result {
  font-size: 1.375rem;
  color: #BFBFBF;
  margin-left: 1.375rem; }
  .price-result__price {
    font-size: 2.4375rem;
    line-height: normal;
    color: #524F69;
    margin-right: 0.25rem; }
  .price-result__currency {
    font-size: 1.5625rem;
    line-height: normal;
    color: #524F69; }

.button-submit {
  font-size: 1.25rem;
  font-weight: 500;
  padding: .9rem 1.8rem;
  cursor: pointer;
  color: #ffffff;
  background: #514A8F;
  border: 2px solid transparent;
  border-radius: 5px;
  margin-top: 1.5rem; }
  .button-submit:hover {
    background: transparent;
    border-color: #ffa2a2;
    color: #514A8F; }

.passport-details p {
  margin-top: 1.875rem; }

.checkbox input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .checkbox input:checked + .checkbox__text {
    color: #524F69; }
  .checkbox input:checked + .checkbox__text:before {
    background: #514A8F;
    border: 1px solid #514A8F; }
  .checkbox input:checked + .checkbox__text:after {
    opacity: 1; }

.checkbox__text {
  position: relative;
  padding-left: 47px;
  font-size: 20px;
  line-height: normal;
  color: #BFBFBF;
  cursor: pointer; }
  .checkbox__text:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border: 1px solid #D1D1D1;
    border-radius: 4px;
    background: #fff;
    transition: .2s; }
  .checkbox__text:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 9px;
    width: 7px;
    height: 15px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg);
    transition: .2s; }

.radio input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 7px; }
  .radio input:checked + .radio__text:after {
    background: #FF8888;
    opacity: 1; }
  .radio input:checked + .radio__text:before {
    border: 2px solid #FF8888; }

.radio__text {
  position: relative;
  padding-left: 38px;
  cursor: pointer;
  font-size: 1.5625rem;
  line-height: 25px; }
  .radio__text:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 23px;
    height: 23px;
    border: 2px solid #524F69;
    border-radius: 50%;
    background: #fff; }
  .radio__text:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #FF8888;
    opacity: 0;
    transition: .2s; }

.input {
  position: relative;
  padding-top: 1.25rem;
  margin-top: 0.625rem;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding-right: 1px; }
  .input label {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.9375rem;
    opacity: 1;
    transform: translateY(0);
    transition: all 0.2s ease-out;
    z-index: 1; }
  .input input {
    box-sizing: border-box;
    font-size: 1.25rem;
    padding: 1.125rem 0 0.875rem 1.375rem;
    width: 100%;
    border: 0.75px solid #524F69;
    border-radius: 0.375rem;
    color: #524F69;
    z-index: 5; }
    .input input::placeholder {
      color: #BFBFBF; }
  .input input:placeholder-shown + label {
    opacity: 0;
    transform: translateY(1rem); }
  .input input:placeholder-shown {
    border: 0.75px solid #BFBFBF; }

.entity, .individual {
  position: absolute;
  top: 0;
  opacity: 0;
  min-width: calc(100vw - 7rem);
  max-width: 1920px;
  transition: .5s ease-in; }

.individual {
  transform: translateX(-100vw); }

.entity {
  transform: translateX(100vw); }

.active {
  opacity: 1;
  position: static;
  display: block;
  transform: translateX(0);
  min-width: auto; }

@media screen and (max-width: 1050px) {
  .tin p {
    margin-top: 1.875rem; }
  .tin h3 {
    margin-top: 1.7rem; }
  .delivery-form__price {
    margin-top: 3rem;
    flex-wrap: wrap; }
  .delivery-form__main {
    flex-wrap: wrap; }
  .delivery-form__tabs {
    margin-bottom: 2rem; }
  .price-result {
    width: 100%;
    text-align: right; }
  .column {
    width: 100%; }
  .contact-details .checkbox {
    margin: 1.7rem 0; }
  .passport-details p {
    margin: 1rem 0 1.7rem 0; }
  .delivery-city h3 {
    margin-top: 1.7rem; }
  .delivery-city .checkbox {
    margin-top: 1.7rem; }
  .delivery-address {
    margin-top: 1.7rem; }
  .in-garbage {
    font-size: 1.7rem; }
    .in-garbage__text {
      margin-left: 1rem; }
    .in-garbage svg {
      width: 34px;
      height: auto; } }

@media screen and (max-width: 734px) {
  .delivery-form {
    padding: 2rem; }
    .delivery-form__tabs .radio:not(:last-child) {
      margin-right: 5%; }
  .price-result__price {
    display: block; }
  .button-submit {
    font-size: 1.3rem;
    padding: 1rem 2rem; } }

@media screen and (max-width: 530px) {
  .delivery-form__tabs {
    flex-direction: column; }
    .delivery-form__tabs .radio {
      padding-bottom: 1rem; }
    .delivery-form__tabs .radio:not(:last-child) {
      margin-right: 0; }
  .checkbox__text {
    font-size: 1.5rem;
    padding-left: 30px;
    padding-top: 1px; } }

.payment-form {
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  padding: 5.0625rem 6.0625rem 2.5rem 2.3125rem;
  margin-bottom: 4.25rem;
  margin-top: 1.5625rem; }

.payment .row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }
  .payment .row .button-submit {
    margin-top: 0; }
  .payment .row .checkbox {
    width: 30%; }
    .payment .row .checkbox__text a {
      text-decoration: none;
      color: #514A8F; }
      .payment .row .checkbox__text a:hover {
        color: #FF8888; }
  .payment .row:not(:nth-child(1)) {
    margin-top: 2.8125rem; }
  .payment .row:nth-child(2) {
    align-items: flex-end; }
  .payment .row:last-child {
    justify-content: flex-end; }

.payment .radio {
  display: flex;
  align-items: center; }
  .payment .radio svg {
    margin-right: 1rem; }

.payment h2 {
  color: #524F69;
  font-weight: 500;
  font-size: 2.1875rem;
  margin-top: 1.5625rem; }

.payment .payment-price {
  min-width: 28%; }

.payment .payment-total {
  font-style: normal;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 3.8125rem;
  color: #524F69; }
  .payment .payment-total__price {
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 3.6875rem;
    color: #FF8888; }
  .payment .payment-total__currency {
    font-size: 1.5625rem;
    line-height: 1.8125rem;
    color: #FF8888; }

.payment .payment-order {
  display: flex;
  justify-content: space-between; }
  .payment .payment-order__text {
    font-size: 1.375rem;
    line-height: 2rem;
    color: #BFBFBF; }
  .payment .payment-order__price {
    color: #524F69;
    font-size: 2.4375rem;
    line-height: 2.875rem; }
  .payment .payment-order__currency {
    font-size: 25px;
    line-height: 29px;
    color: #524F69; }
  .payment .payment-order-container {
    margin-left: 1rem; }
  .payment .payment-order:nth-child(1) .payment-order__text {
    align-self: end; }
  .payment .payment-order:nth-child(2) {
    margin-top: 3.4375rem; }
    .payment .payment-order:nth-child(2) .payment-order-container {
      align-self: end; }

.edit-order__text {
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 2.5625rem;
  color: #514A8F; }
  .edit-order__text:hover {
    color: #FF8888; }

@media screen and (max-width: 1450px) {
  .payment .row:nth-child(2) {
    flex-wrap: wrap; }
    .payment .row:nth-child(2) .checkbox {
      order: 1;
      width: 50%; }
    .payment .row:nth-child(2) .edit-order {
      order: 3;
      width: 100%;
      margin-top: 1rem; }
    .payment .row:nth-child(2) .payment-total {
      order: 2; }
  .payment .row:nth-child(3) {
    margin-top: 0; } }

@media screen and (max-width: 1240px) {
  .payment-form {
    padding: 2rem; }
  .payment .row:nth-child(1) {
    flex-wrap: wrap; }
  .payment .payment-price {
    width: 100%; }
  .payment .radio:nth-child(2) {
    margin-right: auto;
    margin-left: 5%; }
  .payment .payment-order {
    justify-content: end; }
    .payment .payment-order:nth-child(2) {
      margin-top: 1rem; } }

@media screen and (max-width: 1030px) {
  .payment .row:nth-child(2) .payment-total {
    order: 0;
    margin-left: auto; }
  .payment .row:nth-child(2) .checkbox {
    width: 100%;
    margin: 1rem 0; } }

@media screen and (max-width: 740px) {
  .payment .row:nth-child(1) {
    flex-direction: column; }
    .payment .row:nth-child(1) .radio:nth-child(2) svg {
      margin-right: 1.35rem; }
  .payment .row:nth-child(2) {
    margin-top: 1rem; }
  .payment .row:last-child {
    margin-top: 1rem; }
  .payment .radio:nth-child(2) {
    margin-right: 0;
    margin-left: 0; }
  .payment .payment-order__price {
    font-size: 1.7rem;
    line-height: normal; }
  .payment .payment-order__currency {
    font-size: 1rem;
    line-height: normal; }
  .payment .payment-total {
    font-size: 2rem; }
    .payment .payment-total__price {
      font-size: 2.5rem; }
  .edit-order__text {
    font-size: 1.6rem; } }

@media screen and (max-width: 560px) {
  .payment .row:nth-child(2) .payment-total {
    margin-left: 0; }
  .payment .payment-order {
    margin-top: 1rem;
    justify-content: flex-start; } }

@media screen and (max-width: 430px) {
  .payment .radio:first-child {
    margin-bottom: .5rem; }
  .payment .radio svg {
    display: none; }
  .payment .payment-order {
    flex-direction: column; }
    .payment .payment-order:nth-child(1) .payment-order__text {
      align-self: start; }
    .payment .payment-order:nth-child(2) .payment-order-container {
      align-self: start; }
    .payment .payment-order-container {
      margin-left: 0; }
    .payment .payment-order__text br {
      display: none; } }

.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 0; }
  .page-not-found h2 {
    font-size: 2.8125rem;
    line-height: 3.3125rem;
    color: #524F69; }
  .page-not-found__svg {
    padding: 1.5rem 0;
    overflow: visible; }
    .page-not-found__svg path:nth-child(1) {
      animation: 7s linear infinite motion-y; }
    .page-not-found__svg path:nth-child(2) {
      animation: 6s linear infinite motion-x; }
    .page-not-found__svg path:nth-child(3) {
      animation: 10s linear infinite motion-x; }
    .page-not-found__svg path:nth-child(4) {
      animation: 5s linear infinite motion-x; }
    .page-not-found__svg path:nth-child(5) {
      animation: 8s linear infinite motion-x; }
    .page-not-found__svg path:nth-child(6) {
      animation: 10s linear infinite motion-y; }
    .page-not-found__svg path:nth-child(7) {
      animation: 4s linear infinite motion-y; }
    .page-not-found__svg path:nth-child(9) {
      animation: 7s linear infinite motion-y; }
    .page-not-found__svg path:nth-child(12) {
      animation: 6s linear infinite motion-y; }
    .page-not-found__svg path:nth-child(13) {
      animation: 8s linear infinite motion-y; }

.button-to-home {
  color: #FFFFFF;
  font-size: 1.875rem;
  padding: 1.625rem 4.875rem;
  background: #FF8E8E;
  border-radius: 0.3125rem;
  cursor: pointer; }
  .button-to-home:hover {
    background: #514A8F; }

@keyframes motion-x {
  0%, 100% {
    transform: translateX(30px); }
  50% {
    transform: translateX(-30px); } }

@keyframes motion-y {
  0%, 100% {
    transform: translateY(30px); }
  50% {
    transform: translateY(-30px); } }

@media screen and (max-width: 970px) {
  .page-not-found h2 {
    font-size: 2rem; }
  .button-to-home {
    padding: 1rem 4rem; } }

@media screen and (max-width: 610px) {
  .page-not-found__svg {
    width: 400px;
    height: auto; } }

@media screen and (max-width: 450px) {
  .page-not-found__svg {
    width: 80%; }
  .button-to-home {
    padding: 1rem 3rem;
    font-size: 1.5rem; } }

.text-page {
  background-color: #fff; }
  .text-page .wrapper {
    display: flex;
    padding-top: 26px; }
    @media (max-width: 1000px) {
      .text-page .wrapper {
        flex-direction: column; } }

@media (max-width: 1000px) {
  .text-blocks {
    width: 100%; } }

.text-blocks {
  margin-bottom: 30px; }
  @media (max-width: 1400px) {
    .text-blocks {
      margin-bottom: 26px; } }
  @media (max-width: 1000px) {
    .text-blocks {
      margin-bottom: 22px; } }
  @media (max-width: 768px) {
    .text-blocks {
      margin-bottom: 15px; } }
  .text-blocks h1 {
    margin-top: 21px;
    margin-bottom: 32px;
    width: 100%; }
    @media (max-width: 1400px) {
      .text-blocks h1 {
        font-size: 1.9875rem;
        margin-top: 18px;
        margin-bottom: 29px; } }
    @media (max-width: 1000px) {
      .text-blocks h1 {
        margin-top: 16px;
        margin-bottom: 20px;
        text-align: center; } }
    @media (max-width: 768px) {
      .text-blocks h1 {
        text-align: center;
        margin-top: 14px;
        margin-bottom: 15px; } }
  .text-blocks h2 {
    font-size: 1.75rem;
    font-weight: 500;
    color: #524F69;
    margin-bottom: 15px;
    width: 100%; }
    @media (max-width: 1400px) {
      .text-blocks h2 {
        font-size: 1.5rem;
        margin-bottom: 12px; } }
    @media (max-width: 1000px) {
      .text-blocks h2 {
        margin-bottom: 10px;
        text-align: center; } }
    @media (max-width: 768px) {
      .text-blocks h2 {
        text-align: center;
        margin-bottom: 7px; } }
  .text-blocks h3 {
    margin-bottom: 13px; }
    @media (max-width: 1000px) {
      .text-blocks h3 {
        font-size: 1.2775rem;
        text-align: center; } }
  .text-blocks h4 {
    font-size: 1.3775rem;
    font-weight: 500;
    margin-bottom: 10px; }
    @media (max-width: 1000px) {
      .text-blocks h4 {
        font-size: 1.2275rem;
        text-align: center; } }
  .text-blocks h5 {
    font-size: 1.3275rem;
    font-weight: 500;
    margin-bottom: 7px; }
    @media (max-width: 1000px) {
      .text-blocks h5 {
        font-size: 1.1775rem;
        text-align: center; } }
  .text-blocks h6 {
    font-size: 1.2775rem;
    font-weight: 500;
    margin-bottom: 5px; }
    @media (max-width: 1000px) {
      .text-blocks h6 {
        font-size: 1.1275rem;
        text-align: center; } }
  .text-blocks a {
    color: #FF8888; }
  .text-blocks p {
    font-size: 1.375rem;
    color: #4F4F4F;
    line-height: 3rem;
    max-width: 968px;
    width: 100%;
    margin-bottom: 10px; }
    @media (max-width: 1400px) {
      .text-blocks p {
        line-height: 2.8rem;
        font-size: 1.25rem; } }
    @media (max-width: 1000px) {
      .text-blocks p {
        font-size: 1.125rem;
        line-height: 2.4rem; } }
    @media (max-width: 768px) {
      .text-blocks p {
        font-size: 1rem;
        line-height: 2rem; } }
  .text-blocks strong {
    font-weight: 700; }
  .text-blocks em {
    font-style: italic; }
  .text-blocks img {
    margin-top: 33px;
    width: 100%;
    max-width: 968px; }
    @media (max-width: 1400px) {
      .text-blocks img {
        margin-top: 28px; } }
    @media (max-width: 1000px) {
      .text-blocks img {
        margin-top: 23px; } }
    @media (max-width: 768px) {
      .text-blocks img {
        margin-top: 18px; } }
  .text-blocks ol li, .text-blocks ul li {
    font-size: 1.375rem;
    color: #4F4F4F;
    line-height: 1.5625rem;
    margin-bottom: 13px;
    position: relative; }
    .text-blocks ol li ol li, .text-blocks ol li ul li, .text-blocks ul li ol li, .text-blocks ul li ul li {
      margin-bottom: 3px; }
    @media (max-width: 1400px) {
      .text-blocks ol li, .text-blocks ul li {
        font-size: 0.875rem;
        line-height: 1.4375rem;
        margin-bottom: 8px; } }
    .text-blocks ol li span, .text-blocks ul li span {
      color: #333333;
      font-weight: 400; }
  .text-blocks ol {
    padding-left: 30px; }
  .text-blocks ul {
    padding-left: 30px; }
    .text-blocks ul li:before {
      content: '•';
      display: block;
      position: absolute;
      left: -15px;
      color: #514A8F; }
  .text-blocks table {
    box-sizing: border-box; }
    .text-blocks table thead {
      background-color: #E9EFF7; }
      .text-blocks table thead td {
        height: 77px;
        vertical-align: middle; }
        @media (max-width: 1400px) {
          .text-blocks table thead td {
            height: 50px; } }
        @media (max-width: 1000px) {
          .text-blocks table thead td {
            height: 40px; } }
      .text-blocks table thead span {
        font-size: 1.125rem; }
        @media (max-width: 1400px) {
          .text-blocks table thead span {
            font-size: 1rem; } }
        @media (max-width: 1000px) {
          .text-blocks table thead span {
            font-size: 0.875rem; } }
    .text-blocks table tbody {
      border-left: 1px solid #E5E5E5;
      border-right: 1px solid #E5E5E5; }
      .text-blocks table tbody tr {
        border-bottom: 1px solid #dcdcdc; }
      .text-blocks table tbody td {
        height: 77px;
        vertical-align: middle; }
        @media (max-width: 1400px) {
          .text-blocks table tbody td {
            height: 50px; } }
        @media (max-width: 1000px) {
          .text-blocks table tbody td {
            height: 40px; } }
      .text-blocks table tbody span {
        font-size: 1.375rem; }
        @media (max-width: 1400px) {
          .text-blocks table tbody span {
            font-size: 1.25rem; } }
        @media (max-width: 1000px) {
          .text-blocks table tbody span {
            font-size: 1.125rem; } }
        @media (max-width: 480px) {
          .text-blocks table tbody span {
            font-size: 1rem; } }
    .text-blocks table tr td {
      padding-left: 79px; }
      @media (max-width: 1400px) {
        .text-blocks table tr td {
          padding-left: 49px; } }
      @media (max-width: 1000px) {
        .text-blocks table tr td {
          padding-left: 29px; } }
      @media (max-width: 768px) {
        .text-blocks table tr td {
          padding-left: 15px; } }
      @media (max-width: 480px) {
        .text-blocks table tr td {
          padding-left: 10px; } }
      .text-blocks table tr td:last-child {
        padding-right: 79px; }
        @media (max-width: 1400px) {
          .text-blocks table tr td:last-child {
            padding-right: 50px; } }
        @media (max-width: 1000px) {
          .text-blocks table tr td:last-child {
            padding-right: 30px; } }
        @media (max-width: 768px) {
          .text-blocks table tr td:last-child {
            padding-right: 15px; } }
      .text-blocks table tr td span {
        line-height: 28px;
        font-weight: 500;
        color: #524F69; }
        @media (max-width: 1400px) {
          .text-blocks table tr td span {
            line-height: 26px; } }
        @media (max-width: 1000px) {
          .text-blocks table tr td span {
            line-height: 24px; } }
  .text-blocks blockquote {
    max-width: 968px;
    padding: 10px 0;
    padding-left: 21px;
    border-left: 5px solid #514A8F;
    margin: 10px 0; }
    @media (max-width: 1370px) {
      .text-blocks blockquote {
        padding-left: 11px;
        border-left: 3px solid #514A8F; } }

a.download-href {
  display: inline-block;
  padding-left: 25px;
  position: relative;
  color: #514A8F !important; }
  a.download-href:before {
    content: '';
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    background-image: url("../images/file.svg");
    left: 0; }

.tab ul {
  display: inline-flex;
  width: auto;
  list-style: none;
  padding: 0;
  box-sizing: border-box; }
  .tab ul li {
    display: inline-block;
    box-sizing: border-box;
    margin: 0;
    outline: 0;
    border: 1px solid #514A8F;
    border-left: 0; }
    .tab ul li:first-child {
      border-radius: 8px 0 0 8px;
      border: 1px solid #514A8F; }
    .tab ul li:last-child {
      border-radius: 0 8px 8px 0;
      border: 1px solid #514A8F;
      border-left: 0; }
    .tab ul li:hover {
      background-color: #514A8F; }
      .tab ul li:hover a {
        color: #fff; }
    .tab ul li a {
      display: inline-block;
      text-decoration: none;
      font-size: 0.875rem;
      line-height: 2.5rem;
      padding: 0 40px;
      text-align: center;
      color: #514A8F;
      outline: 0; }
      @media (max-width: 1400px) {
        .tab ul li a {
          line-height: 2.1875rem;
          padding: 0 30px; } }
      @media (max-width: 1000px) {
        .tab ul li a {
          line-height: 1.875rem;
          padding: 0 20px; } }
      @media (max-width: 480px) {
        .tab ul li a {
          line-height: 1.875rem;
          padding: 0 15px; } }
  .tab ul .active-tab {
    background-color: #514A8F; }
    .tab ul .active-tab a {
      color: #fff; }

.tab > div {
  display: none; }
  .tab > div.current {
    display: block; }

.contacts {
  background-color: #fff; }
  .contacts .wrapper {
    padding-top: 26px;
    display: flex;
    padding: 0;
    padding-right: 31px; }
    @media (max-width: 1000px) {
      .contacts .wrapper {
        flex-direction: column;
        padding: 0; } }
    .contacts .wrapper .aside-menu {
      margin-right: 43px; }
      @media (max-width: 1700px) {
        .contacts .wrapper .aside-menu {
          min-width: 338px; } }
      @media (max-width: 1400px) {
        .contacts .wrapper .aside-menu {
          margin-right: 23px;
          width: 248px;
          min-width: 248px; } }
      @media (max-width: 1000px) {
        .contacts .wrapper .aside-menu {
          width: 100%; } }
  .contacts-section {
    display: flex;
    flex-wrap: wrap;
    max-width: 1443px;
    width: 100%;
    justify-content: space-between; }
    @media (max-width: 1000px) {
      .contacts-section {
        margin-top: 20px;
        padding: 0 2.5rem; } }
    @media (max-width: 768px) {
      .contacts-section {
        margin-top: 10px;
        padding: 0 1rem; } }
    .contacts-section > div {
      width: 48.69442%;
      margin-bottom: 41px; }
      @media (max-width: 1400px) {
        .contacts-section > div {
          margin-bottom: 20px; } }
      @media (max-width: 768px) {
        .contacts-section > div {
          width: 100%; } }
    .contacts-section h3 {
      font-weight: 700;
      color: #514A8F;
      margin-bottom: 33px; }
      @media (max-width: 1400px) {
        .contacts-section h3 {
          font-size: 1.2375rem;
          margin-bottom: 25px; } }
      @media (max-width: 768px) {
        .contacts-section h3 {
          font-size: 1.0375rem;
          margin-bottom: 20px; } }
    .contacts-section-phones h1 {
      margin-top: 10px;
      margin-bottom: 20px; }
      @media (max-width: 1400px) {
        .contacts-section-phones h1 {
          font-size: 1.9875rem;
          margin-top: 8px;
          margin-bottom: 17px; } }
      @media (max-width: 768px) {
        .contacts-section-phones h1 {
          font-size: 1.7875rem;
          text-align: center; } }
    .contacts-section-phones--block {
      background-color: #FCFCFC;
      padding: 17px 50px 20px 42px;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.17);
      height: 88.5%; }
      @media (max-width: 1400px) {
        .contacts-section-phones--block {
          padding: 25px 20px;
          height: 90.5%; } }
      @media (max-width: 1400px) {
        .contacts-section-phones--block {
          height: 90.5%; } }
      @media (max-width: 768px) {
        .contacts-section-phones--block {
          height: auto; } }
      @media (max-width: 480px) {
        .contacts-section-phones--block {
          padding: 20px 15px; } }
      .contacts-section-phones--block > div {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap; }
        .contacts-section-phones--block > div a {
          font-weight: 500;
          font-size: 1rem;
          color: #524F69;
          text-decoration: none;
          display: block;
          box-sizing: border-box;
          padding-left: 27px;
          position: relative;
          margin-bottom: 28px;
          width: 54.10714%;
          transition: .2s; }
          .contacts-section-phones--block > div a:hover {
            color: #ffa2a2;
            transition: .2s; }
          @media (max-width: 1700px) {
            .contacts-section-phones--block > div a {
              width: 50%; } }
          @media (max-width: 1400px) {
            .contacts-section-phones--block > div a {
              font-size: 0.875rem;
              padding-left: 22px;
              margin-bottom: 23px; } }
          .contacts-section-phones--block > div a:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            width: 12px;
            height: 12px;
            background-image: url(../images/phone.svg); }
          .contacts-section-phones--block > div a:nth-child(even) {
            width: 34.99142%; }
            @media (max-width: 1700px) {
              .contacts-section-phones--block > div a:nth-child(even) {
                width: auto; } }
    .contacts-section-feedback {
      padding: 20px 25px 14px 29px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.114); }
      @media (max-width: 1400px) {
        .contacts-section-feedback {
          padding: 25px 20px; } }
      @media (max-width: 480px) {
        .contacts-section-feedback {
          padding: 20px 15px; } }
      .contacts-section-feedback h3 {
        margin-bottom: 25px; }
      .contacts-section-feedback--form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; }
        .contacts-section-feedback--form .submit {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          margin-top: 12px;
          width: 100%; }
          .contacts-section-feedback--form .submit label {
            display: flex;
            cursor: pointer;
            position: relative;
            width: 100%;
            padding-bottom: 10px; }
            .contacts-section-feedback--form .submit label input[type="checkbox"] {
              position: absolute;
              z-index: -999;
              top: 10px;
              left: 4px; }
            .contacts-section-feedback--form .submit label span {
              padding-left: 30px;
              position: relative;
              font-size: 0.875rem;
              padding-right: 10px;
              line-height: 20px; }
              @media (max-width: 1400px) {
                .contacts-section-feedback--form .submit label span {
                  font-size: 0.75rem;
                  padding-left: 25px;
                  line-height: 18px; } }
              .contacts-section-feedback--form .submit label span:before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                width: 20px;
                height: 20px;
                border: 1px solid #514A8F; }
                @media (max-width: 1400px) {
                  .contacts-section-feedback--form .submit label span:before {
                    width: 15px;
                    height: 15px; } }
            .contacts-section-feedback--form .submit label input:checked + span:before {
              content: '';
              background-image: url("../images/mark.svg");
              background-repeat: no-repeat;
              background-position: center; }
              @media (max-width: 1400px) {
                .contacts-section-feedback--form .submit label input:checked + span:before {
                  background-size: 80%; } }
          .contacts-section-feedback--form .submit #captcha {
            width: 100%; }
            .contacts-section-feedback--form .submit #captcha > div {
              max-width: 300px;
              margin: 0 auto; }
          .contacts-section-feedback--form .submit input[type="submit"] {
            min-width: 159px;
            height: 47px;
            background: #514A8F;
            border-radius: 5px;
            border: 0;
            outline: 0;
            color: #fff;
            transition: all .2s;
            margin-bottom: 0;
            padding: 0;
            margin-top: 10px; }
            @media (max-width: 1700px) {
              .contacts-section-feedback--form .submit input[type="submit"] {
                min-width: 100px;
                height: 30px;
                font-size: 12px; } }
            @media (max-width: 1400px) {
              .contacts-section-feedback--form .submit input[type="submit"] {
                min-width: 80px;
                height: 25px;
                font-size: 10px; } }
            .contacts-section-feedback--form .submit input[type="submit"]:hover {
              background: rgba(81, 74, 143, 0.1);
              transition: all .2s; }
            .contacts-section-feedback--form .submit input[type="submit"]:active {
              background: rgba(81, 74, 143, 0.1); }
            .contacts-section-feedback--form .submit input[type="submit"]:before {
              content: '';
              display: inline-block;
              width: 1;
              height: 1; }
        .contacts-section-feedback--form > label {
          display: block;
          width: 45.96774%; }
          @media (max-width: 1400px) {
            .contacts-section-feedback--form > label {
              width: 48%; } }
          .contacts-section-feedback--form > label:nth-last-child(2), .contacts-section-feedback--form > label:last-child {
            width: 100%; }
          .contacts-section-feedback--form > label span {
            display: block;
            margin-bottom: 5px;
            font-size: 0.875rem;
            line-height: 1rem;
            letter-spacing: 0.01em;
            color: #4F4F4F; }
          .contacts-section-feedback--form > label select {
            display: block;
            height: 45px;
            width: 100%;
            padding-left: 14px;
            color: #CDCDCD;
            border: 0.75px solid #DFE2E5;
            box-sizing: border-box;
            border-radius: 4px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            position: relative;
            background-image: url("../images/arrow-down.svg");
            background-repeat: no-repeat;
            background-position: 92.98246% 62.22222%;
            background-color: #fff; }
            .contacts-section-feedback--form > label select option {
              color: #000; }
            @media (max-width: 1400px) {
              .contacts-section-feedback--form > label select {
                font-size: 11px; } }
          .contacts-section-feedback--form > label textarea {
            resize: none;
            width: 100%;
            border: 0.75px solid #DFE2E5;
            box-sizing: border-box;
            border-radius: 9px;
            height: 112px;
            padding-top: 13px;
            padding-left: 11px;
            font-size: 0.875rem;
            line-height: 1rem;
            color: #4F4F4F; }
            .contacts-section-feedback--form > label textarea::placeholder {
              color: #CDCDCD; }
          .contacts-section-feedback--form > label input {
            height: 45px;
            padding: 0;
            box-sizing: border-box;
            border: 0.75px solid #DFE2E5;
            border-radius: 4px;
            width: 100%;
            background-repeat: no-repeat;
            font-size: 0.875rem;
            line-height: 1rem;
            color: #4F4F4F;
            margin-bottom: 33px;
            padding-left: 47px;
            background-position: 14px center; }
            @media (max-width: 1400px) {
              .contacts-section-feedback--form > label input {
                padding-left: 27px;
                background-position: 7px;
                background-size: 10%;
                font-size: 0.75rem; } }
            @media (max-width: 768px) {
              .contacts-section-feedback--form > label input {
                margin-bottom: 20px;
                background-size: 5%; } }
            @media (max-width: 480px) {
              .contacts-section-feedback--form > label input {
                background-size: 10%; } }
            .contacts-section-feedback--form > label input::placeholder {
              color: #CDCDCD; }
          .contacts-section-feedback--form > label.name input {
            background-image: url("../images/manager.svg"); }
          .contacts-section-feedback--form > label.phone input {
            background-image: url("../images/phone.svg");
            background-size: 6%; }
            @media (max-width: 1400px) {
              .contacts-section-feedback--form > label.phone input {
                background-size: 10%; } }
            @media (max-width: 768px) {
              .contacts-section-feedback--form > label.phone input {
                background-size: 5%; } }
            @media (max-width: 480px) {
              .contacts-section-feedback--form > label.phone input {
                background-size: 10%; } }
          .contacts-section-feedback--form > label.mail input {
            background-image: url("../images/mail.svg");
            background-size: 6%; }
            @media (max-width: 1400px) {
              .contacts-section-feedback--form > label.mail input {
                background-size: 10%; } }
            @media (max-width: 768px) {
              .contacts-section-feedback--form > label.mail input {
                background-size: 5%; } }
            @media (max-width: 480px) {
              .contacts-section-feedback--form > label.mail input {
                background-size: 10%; } }
    .contacts-section-map {
      padding: 29px 22px;
      background: #F4F9FF;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.17); }
      @media (max-width: 1400px) {
        .contacts-section-map {
          padding: 25px 20px;
          height: 450px; } }
      @media (max-width: 768px) {
        .contacts-section-map {
          height: 350px; } }
      @media (max-width: 480px) {
        .contacts-section-map {
          padding: 20px 15px;
          height: 400px; } }
      .contacts-section-map #map {
        width: 100%;
        height: 100%; }
        .contacts-section-map #map .ymaps-2-1-74-image {
          background-image: url("../images/logo.png") !important;
          background-size: cover; }
    .contacts-section-address {
      padding: 24px 37px 30px;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.17);
      min-height: 512px; }
      @media (max-width: 1400px) {
        .contacts-section-address {
          padding: 25px 20px;
          height: 450px;
          min-height: auto; } }
      @media (max-width: 768px) {
        .contacts-section-address {
          height: 350px; } }
      @media (max-width: 480px) {
        .contacts-section-address {
          padding: 20px 15px;
          height: 400px; } }
      .contacts-section-address--tabs {
        display: flex;
        margin-bottom: 37px;
        padding: 0; }
        @media (max-width: 1400px) {
          .contacts-section-address--tabs {
            margin-bottom: 25px; } }
        .contacts-section-address--tabs li {
          width: 50%;
          text-align: center; }
          .contacts-section-address--tabs li:before {
            display: none !important; }
          .contacts-section-address--tabs li.active-address a {
            color: #524F69;
            border-bottom: 2px solid #524F69; }
          .contacts-section-address--tabs li a {
            font-size: 1.5625rem;
            line-height: 2.25rem;
            box-sizing: border-box;
            font-weight: 700;
            color: #BFBFBF;
            text-decoration: none;
            display: inline-block; }
            @media (max-width: 1400px) {
              .contacts-section-address--tabs li a {
                font-size: 1.25rem;
                line-height: 1.9375rem; } }
      .contacts-section-address .address-desc {
        display: block; }
      .contacts-section-address--description {
        max-width: 456px;
        display: none; }
        .contacts-section-address--description p {
          font-size: 0.875rem;
          line-height: 1.4375rem;
          color: #4F4F4F;
          margin-bottom: 20px; }
          @media (max-width: 1400px) {
            .contacts-section-address--description p {
              margin-bottom: 10px; } }
          .contacts-section-address--description p a {
            color: #4F4F4F;
            text-decoration: none;
            display: block; }
          .contacts-section-address--description p span {
            font-weight: 700; }
  .contacts .ymaps-2-1-74-islets_icon-with-caption {
    display: none; }

@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline !important; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  a[href^='#']:after,
  a[href^='javascript:']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #999 !important;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  table {
    border-collapse: collapse !important; }
    table td,
    table th {
      background-color: #fff !important; } }
