.aside-menu {
  min-width: 368px;
  box-sizing: border-box;
  margin-right: 43px;
  @media (max-width: 1400px) {
    margin-right: 43px;
    width: 288px;
    min-width: auto;
  }
  @media (max-width: 1000px) {
    margin-right: 0px;
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }

  nav {
    width: 100%;

    ul {
      box-sizing: border-box;
      border: 1px solid $aside-border;
      @media (max-width: 1000px) {
        display: flex;
      }
      @media (max-width: 480px) {
        flex-direction: column;
      }

      .active-tab {
        background-color: $base-active-color;
        color: $white;
        a {
          color: $white;
        }
      }

      li {
        box-sizing: border-box;
        border-bottom: 1px solid $li-bottom-border;
        @media (max-width: 1000px) {
          width: 30%;
          text-align: center;
          &:first-child{
            width: 20%;
          }
          &:nth-child(3){
            width: 20%;
          }
          &:last-child{
            width: 30%;
          }
        }
        @media (max-width: 768px) {
          width: 25%;
          &:first-child{
            width: 15%;
          }
          &:nth-child(3){
            width: 18%;
          }
          &:last-child{
            width: 43%;
          }
        }
        @media (max-width: 480px) {
          width: 100%;
          text-align: center;
          &:first-child{
            width: 100%;
          }
          &:nth-child(3){
            width: 100%;
          }
          &:last-child{
            width: 100%;
          }
      }

        a {
          display: block;
          font-size: rem(20);
          line-height: rem(70);
          color: $black;
          font-weight: 500;
          text-decoration: none;
          padding-left: 27px;
          transition: all .2s;
          @media (max-width: 1400px) {
            font-size: rem(16);
            line-height: rem(60);
            padding-left: 23px;
          }
          @media (max-width: 1000px) {
            font-size: rem(14);
            line-height: rem(50);
            padding-left: 18px;
          }
          @media (max-width: 768px) {
            font-size: rem(14);
            line-height: rem(50);
            padding-left: 0px;
          }

          &:hover {
            background-color: $base-active-color;
            color: $white;
            transition: all .2s;
          }


        }
      }
    }
  }
}