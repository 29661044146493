.text {
  &-page {
    background-color: $white;

    .wrapper {
      display: flex;
      padding-top: 26px;
      @media (max-width: 1000px) {
        flex-direction: column;
      }
    }
  }

  &-blocks {

    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  &-blocks {
    margin-bottom: 30px;
    @media (max-width: 1400px) {
      margin-bottom: 26px;
    }
    @media (max-width: 1000px) {
      margin-bottom: 22px;
    }
    @media (max-width: 768px) {
      margin-bottom: 15px;
    }

    h1 {
      margin-top: 21px;
      margin-bottom: 32px;
      width: 100%;
      @media (max-width: 1400px) {
        font-size: 1.9875rem;
        margin-top: 18px;
        margin-bottom: 29px;
      }
      @media (max-width: 1000px) {

        margin-top: 16px;
        margin-bottom: 20px;
        text-align: center;
      }
      @media (max-width: 768px) {
        text-align: center;

        margin-top: 14px;
        margin-bottom: 15px;
      }
    }

    h2 {
      font-size: rem(28);
      font-weight: 500;
      color: $base-color;
      margin-bottom: 15px;
      width: 100%;
      @media (max-width: 1400px) {
        font-size: rem(24);
        margin-bottom: 12px;
      }
      @media (max-width: 1000px) {

        margin-bottom: 10px;
        text-align: center;
      }
      @media (max-width: 768px) {
        text-align: center;

        margin-bottom: 7px;
      }
    }
    h3{
      margin-bottom: 13px;
      @media (max-width: 1000px) {
        font-size: 1.2775rem;
        text-align: center;
      }
    }
    h4{
      font-size: 1.3775rem;
    font-weight: 500;
      margin-bottom: 10px;
      @media (max-width: 1000px) {
        font-size: 1.2275rem;
        text-align: center;
      }
    }
    h5{
      font-size: 1.3275rem;
    font-weight: 500;
      margin-bottom: 7px;
      @media (max-width: 1000px) {
        font-size: 1.1775rem;
        text-align: center;
      }
    }
    h6{
      font-size: 1.2775rem;
    font-weight: 500;
      margin-bottom: 5px;
      @media (max-width: 1000px) {
        font-size: 1.1275rem;
        text-align: center;
      }
    }

    a {
        color: $link-color;
      }

    p {
      font-size: rem(22);
      color: $gray-dark;
      line-height: 3rem;
      max-width: 968px;
      width: 100%;
      margin-bottom: 10px;
      @media (max-width: 1400px) {
        line-height: 2.8rem;
        font-size: rem(20);
      }
      @media (max-width: 1000px) {
        font-size: rem(18);
        line-height: 2.4rem;
      }
      @media (max-width: 768px) {
        font-size: rem(16);
        line-height: 2rem;
      }

    }
    strong{
      font-weight: 700;
    }
    em {
      font-style: italic;
    }

    img {
      margin-top: 33px;
      width: 100%;
      max-width: 968px;
      @media (max-width: 1400px) {
        margin-top: 28px;
      }
      @media (max-width: 1000px) {
        margin-top: 23px;
      }
      @media (max-width: 768px) {
        margin-top: 18px;
      }
    }



    ol, ul {
      li {
        font-size: rem(22);
        color: $gray-dark;
        line-height: rem(25);
        margin-bottom: 13px;
        position: relative;
        ol,ul{
          li{
            margin-bottom: 3px;
          }

        }
        @media (max-width: 1400px) {
          font-size: rem(14);
          line-height: rem(23);
          margin-bottom: 8px;
        }

        span {
          color: $black;
          font-weight: 400;
        }
      }
    }

    ol {
      padding-left: 30px;
    }

    ul {
      padding-left: 30px;
      li{
        &:before{
          content: '•';
          display: block;
          position: absolute;
          left: -15px;
          color: #514A8F;
        }
      }
    }

    table {
      box-sizing: border-box;

      thead {
        background-color: $table-thead-bg-color;

        td {
          height: 77px;
          vertical-align: middle;
          @media (max-width: 1400px) {
            height: 50px;
          }
          @media (max-width: 1000px) {
            height: 40px;
          }
        }

        span {
          font-size: rem(18);
          @media (max-width: 1400px) {
            font-size: rem(16);
          }
          @media (max-width: 1000px) {
            font-size: rem(14);
          }
        }
      }

      tbody {
        border-left: 1px solid $aside-border;
        border-right: 1px solid $aside-border;

        tr {
          border-bottom: 1px solid $table-tr-bottom-border;
        }

        td {
          height: 77px;
          vertical-align: middle;
          @media (max-width: 1400px) {
            height: 50px;
          }
          @media (max-width: 1000px) {
            height: 40px;
          }
        }

        span {
          font-size: rem(22);
          @media (max-width: 1400px) {
            font-size: rem(20);
          }
          @media (max-width: 1000px) {
            font-size: rem(18);
          }
          @media (max-width: 480px) {
            font-size: rem(16);
          }
        }
      }

      tr {
        td {
          padding-left: 79px;
          @media (max-width: 1400px) {
            padding-left: 49px;
          }
          @media (max-width: 1000px) {
            padding-left: 29px;
          }
          @media (max-width: 768px) {
            padding-left: 15px;
          }
          @media (max-width: 480px) {
            padding-left: 10px;
          }

          &:last-child {
            padding-right: 79px;
            @media (max-width: 1400px) {
              padding-right: 50px;
            }
            @media (max-width: 1000px) {
              padding-right: 30px;
            }
            @media (max-width: 768px) {
              padding-right: 15px;
            }
          }

          span {
            line-height: 28px;
            font-weight: 500;
            color: $base-color;
            @media (max-width: 1400px) {
              line-height: 26px;
            }
            @media (max-width: 1000px) {
              line-height: 24px;
            }
          }
        }
      }
    }

    blockquote{
      max-width: 968px;
      padding: 10px 0;
      padding-left: 21px;
      border-left: 5px solid $base-active-color;
      margin: 10px 0;
      @media (max-width: 1370px){
        padding-left: 11px;
        border-left: 3px solid $base-active-color;
      }
    }


  }
}


    a.download-href {
      display: inline-block;
      padding-left: 25px;
      position: relative;
      color: $base-active-color!important;

      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 18px;
        height: 18px;
        background-image: url("../images/file.svg");
        left: 0;
      }
    }

    .tab {
      ul {
        display: inline-flex;
        width: auto;
        list-style: none;
        padding: 0;
        box-sizing: border-box;

        li {
          display: inline-block;
          box-sizing: border-box;
          margin: 0;
          outline: 0;
          border: 1px solid $base-active-color;
          border-left: 0;

          &:first-child {
            border-radius: 8px 0 0 8px;
            border: 1px solid $base-active-color;
          }

          &:last-child {
            border-radius: 0 8px 8px 0;
            border: 1px solid $base-active-color;
            border-left: 0;
          }

          &:hover {
            background-color: $base-active-color;

            a {
              color: $white;
            }
          }

          a {
            display: inline-block;
            text-decoration: none;
            font-size: rem(14);
            line-height: rem(40);
            padding: 0 40px;
            text-align: center;
            color: $base-active-color;
            outline: 0;
            @media (max-width: 1400px) {
              line-height: rem(35);
              padding: 0 30px;
            }
            @media (max-width: 1000px) {
              line-height: rem(30);
              padding: 0 20px;
            }
            @media (max-width: 480px) {
              line-height: rem(30);
              padding: 0 15px;
            }
          }
        }

        .active-tab {
          background-color: $base-active-color;

          a {
            color: $white;
          }
        }
      }

      &>div {
        display: none;

        &.current {
          display: block;
        }
      }

    }
