/**
 * Преобразование пикселей в rem на основе переменной с базовым размером шрифта.
 * @param  {number} $px Число пикселей (с единицами измерения или без)
 * @param  {string} Число пикселей, размер контентного шрифта на проекте
 * @return {string} Результат преобразования $px в rem
 */

$font-size--root: 16px !default;

@function rem($px, $font-size: $font-size--root) {
  @return ((($px / ($px * 0 + 1)) * 100 / ($font-size / 1px)) / 100) * 1rem;
}
