// Семантические цвета

$base-color: #524F69;
$base-active-color: #514A8F;

$white: #fff;
$gray: #BFBFBF ;
$gray-dark: #4F4F4F;
$light-dark: #D1D1D1;
$black: #333333;
$aside-border: #E5E5E5;
$li-bottom-border: rgba(147, 147, 147, .33);
$table-tr-bottom-border: #dcdcdc;
$gray-form: #CDCDCD;

$body-bg-color: #F9FDFF;
$map-bg: #F4F9FF;
$table-thead-bg-color: #E9EFF7;
$gray-bg: #FCFCFC;
$black-arrow-bg: #1d1d1d;
$gray-border-form: #DFE2E5;
$dark-white-bg: #FBFDFF;

$link-color: #FF8888;
$link-color--hover: lighten($link-color, 5%);
$link-color--active: darken($link-color, 10%);

// Базовая типографика
$font-size: 16px; // rem(16px)
$font-family: 'Roboto', sans-serif;
$line-height: normal;

// Ширины
$wrapper: (
'max-width': 1920px,
'min-width': 280px,
'padding': 0 2.5rem 
);
