.top-categories{
    display: flex;
    padding: 0.6rem 0;
    transition: none;
    max-width: 1920px;
    min-width: 280px;
    margin: 0 auto;
    &-container{
            border: 1px solid rgba(0, 0, 0, 0.136);
    }
    .item{
        margin-left: 5px;
        padding: .6rem 2.575rem;
        border: 2px solid $link-color;
        border-radius: 5px;
        text-decoration: none;
        color: $base-color;
        font-size: .9rem;
        line-height: 2rem;
        font-weight: 500;
        @extend .transition;
        &:hover{
            color: $white;
            background-color: $link-color;
            border-color: $link-color;
        }
        &:last-child {
            margin-right: 5px;
        }
    }
    &-menu-hide{
        display: none;
    }
    &-menu {
        justify-content: flex-end;
        align-items: center;
        padding: 0 1rem;
        display: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.136);
        &__button {
            display: flex;
            align-items: center;
            cursor: pointer;
            svg {
                width: 20px;
                height: 20px;
                padding: 1rem;
                fill: #514A8F;
            }
            p {
                line-height: 20px;
                font-size: 20px;
                text-transform: uppercase;
                // opacity: .4;
                color: #514A8F;
            }

            &:hover {
                svg {
                    fill: #FF8888;
                }

                p {
                    color: #FF8888;
                }
            }
        }
        .active {
            display: flex;

            svg {
                transition: .3s ease-in;
                fill: #FF8888;
                rect {
                    &:nth-child(3) {
                        transform: translateY(100px);
                    }
                    &:nth-child(4) {
                        transform: translateX(-100px);
                    }
                    &:nth-child(7) {
                        transform: translateY(-100px);
                    }
                    &:nth-child(8) {
                        transform: translateX(100px);
                    }
                }
            }

            p {
                color: #FF8888;
            }

            &:hover {
                svg {
                    fill: #514A8F;
                }

                p {
                    color: #514A8F;
                }
            }
        }
    }
}

@media screen {
    @media (max-width: 1500px) {
        .top-categories {
            .item {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-basis: (100% / 6);
                flex-grow: 1;
                flex-shrink: 1;
                font-size: 1rem;
                padding: .6rem 0.875rem;
                line-height: 1.7;
                text-align: center;
            }
        }
    }
    @media (max-width: 840px) {
        .top-categories {
            flex-direction: column;
            align-items: center;
            padding: 1rem;
            border-top: none;
            .item {
                width: 100%;
                margin-left: 0;
                text-align: center;
                line-height: 1.5;
                margin-bottom: .5rem;
                &:last-child {
                    margin-right: 0;
                    margin-bottom: 0;
                }
            }
            &-menu {
                display: flex;
            }
            &-container {
                transition: none;
                display: none;
            }
        }
    }
}