.contacts {
  background-color: $white;

  .wrapper {
    padding-top: 26px;
    display: flex;
    padding: 0;
    padding-right: 31px;
    @media (max-width: 1000px) {
      flex-direction: column;
      padding: 0;
    }
    @media (max-width: 700px) {
    }
    .aside-menu {
      margin-right: 43px;
      @media(max-width: 1700px) {
        min-width: 338px;
      }
      @media (max-width: 1400px) {
        margin-right: 23px;
        width: 248px;
        min-width: 248px;
      }
      @media (max-width: 1000px) {
        width: 100%;
      }
    }
  }

  &-section {
    display: flex;
    flex-wrap: wrap;
    max-width: 1443px;
    width: 100%;
    justify-content: space-between;
    @media (max-width: 1000px) {
      margin-top: 20px;
      padding: 0 2.5rem;
    }
    @media (max-width: 768px) {
      margin-top: 10px;
      padding: 0 1rem;
    }

    & > div {
      width: percentage(690 / 14.17 / 100);
      margin-bottom: 41px;
      @media (max-width: 1400px) {
        margin-bottom: 20px;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }

    h3 {
      font-weight: 700;
      color: $base-active-color;
      margin-bottom: 33px;
      @media (max-width: 1400px) {
        font-size: 1.2375rem;
        margin-bottom: 25px;
      }
      @media (max-width: 768px) {
        font-size: 1.0375rem;
        margin-bottom: 20px;
      }
    }

    &-phones {
      h1 {
        margin-top: 10px;
        margin-bottom: 20px;
        @media (max-width: 1400px) {
          font-size: 1.9875rem;
          margin-top: 8px;
          margin-bottom: 17px;
        }
        @media (max-width: 768px) {
          font-size: 1.7875rem;
          text-align: center;
        }
      }

      &--block {
        background-color: $gray-bg;
        padding: 17px 50px 20px 42px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.17);
        height: 88.5%;
        @media (max-width: 1400px) {
          padding: 25px 20px;
          height: 90.5%;
        }
        @media (max-width: 1400px) {
          height: 90.5%;
        }

        @media (max-width: 768px) {
          height: auto;
        }
        @media (max-width: 480px) {
          padding: 20px 15px;
        }

        > div {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          a {
            font-weight: 500;
            font-size: rem(16);
            color: $base-color;
            text-decoration: none;
            display: block;
            box-sizing: border-box;
            padding-left: 27px;
            position: relative;
            margin-bottom: 28px;
            width: percentage(303 / 5.6 / 100);
            transition: .2s;
            &:hover{
              color: #ffa2a2;
              transition: .2s;
            }
            @media(max-width: 1700px) {
              width: 50%;
            }
            @media (max-width: 1400px) {
              font-size: rem(14);
              padding-left: 22px;
              margin-bottom: 23px;
            }

            &:before {
              content: '';
              display: block;
              position: absolute;
              left: 0;
              width: 12px;
              height: 12px;
              background-image: url(../images/phone.svg);
            }

            &:nth-child(even) {
              width: percentage(204 / 5.83 / 100);
              @media(max-width: 1700px) {
                width: auto;
              }
            }
          }
        }
      }
    }

    &-feedback {
      padding: 20px 25px 14px 29px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.114);
      @media(max-width: 1400px) {
        padding: 25px 20px;
      }
      @media (max-width: 480px) {
          padding: 20px 15px;
        }

      h3 {
        margin-bottom: 25px;
      }

      &--form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .submit {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          margin-top: 12px;
          width: 100%;
          label {
            display: flex;
            cursor: pointer;
            position: relative;
            width: 100%;
            padding-bottom: 10px;
            input[type="checkbox"] {
              position: absolute;
              z-index: -999;
              top: 10px;
              left: 4px;
            }

            span {
              padding-left: 30px;
              position: relative;
              font-size: rem(14);
              padding-right: 10px;
              line-height: 20px;
              @media (max-width: 1400px) {
                font-size: rem(12);
                padding-left: 25px;
                line-height: 18px;
              }

              &:before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                width: 20px;
                height: 20px;
                border: 1px solid $base-active-color;
                @media (max-width: 1400px) {
                  width: 15px;
                  height: 15px;
                }
              }
            }

            input:checked + span {
              &:before {
                content: '';
                background-image: url("../images/mark.svg");
                background-repeat: no-repeat;
                background-position: center;
                @media (max-width: 1400px) {
                  background-size: 80%;
                }
              }
            }
          }

          #captcha{
            width: 100%;
            &>div{
              max-width: 300px;
              margin: 0 auto;
            }
          }

          input[type="submit"] {
            min-width: 159px;
            height: 47px;
            background: $base-active-color;
            border-radius: 5px;
            border: 0;
            outline: 0;
            color: $white;
            transition: all .2s;
            margin-bottom: 0;
            padding: 0;
            margin-top: 10px;
            @media(max-width: 1700px) {
              min-width: 100px;
              height: 30px;
              font-size: 12px;
            }
            @media (max-width: 1400px) {
              min-width: 80px;
              height: 25px;
              font-size: 10px;
            }

            &:hover {
              background: rgba(81, 74, 143, .1);
              transition: all .2s;
            }

            &:active {
              background: rgba(81, 74, 143, .1);
            }

            &:before {
              content: '';
              display: inline-block;
              width: 1;
              height: 1;
            }
          }
        }


        & > label {
          display: block;
          width: percentage(285 / 6.2 / 100);
          @media (max-width: 1400px) {
            width: 48%;
          }

          &:nth-last-child(2), &:last-child {
            width: 100%;
          }

          span {
            display: block;
            margin-bottom: 5px;
            font-size: rem(14);
            line-height: rem(16);
            letter-spacing: 0.01em;
            color: $gray-dark;
          }

          select {
            display: block;
            height: 45px;
            width: 100%;
            padding-left: 14px;
            color: $gray-form;
            border: 0.75px solid $gray-border-form;
            box-sizing: border-box;
            border-radius: 4px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            position: relative;
            background-image: url("../images/arrow-down.svg");
            background-repeat: no-repeat;
            background-position: percentage(265 / 2.85/ 100) percentage(28 / .45/ 100);
            background-color: $white;
            option{
              color: #000;
            }
            @media (max-width: 1400px) {
              font-size: 11px;
            }
          }

          textarea {
            resize: none;
            width: 100%;
            border: 0.75px solid $gray-border-form;
            box-sizing: border-box;
            border-radius: 9px;
            height: 112px;
            padding-top: 13px;
            padding-left: 11px;
            font-size: rem(14);
            line-height: rem(16);
            color: $gray-dark;

            &::placeholder {
              color: $gray-form;
            }
          }


          input {
            height: 45px;
            padding: 0;
            box-sizing: border-box;
            border: 0.75px solid $gray-border-form;
            border-radius: 4px;
            width: 100%;
            background-repeat: no-repeat;
            font-size: rem(14);
            line-height: rem(16);
            color: $gray-dark;
            margin-bottom: 33px;
            padding-left: 47px;
            background-position: 14px center;
            @media (max-width: 1400px) {
              padding-left: 27px;
              background-position: 7px;
              background-size: 10%;
              font-size: rem(12);
            }
            @media (max-width: 768px) {
              margin-bottom: 20px;
              background-size: 5%;
            }
            @media (max-width: 480px) {
              background-size: 10%;
            }

            &::placeholder {
              color: $gray-form;
            }
          }

          &.name {
            input {
              background-image: url("../images/manager.svg");

            }
          }

          &.phone {
            input {
              background-image: url("../images/phone.svg");
              background-size: 6%;
              @media (max-width: 1400px) {
                background-size: 10%;
              }
              @media (max-width: 768px) {
              background-size: 5%;
            }
              @media (max-width: 480px) {
              background-size: 10%;
            }
            }
          }

          &.mail {
            input {
              background-image: url("../images/mail.svg");
              background-size: 6%;
              @media (max-width: 1400px) {
                background-size: 10%;
              }
              @media (max-width: 768px) {
              background-size: 5%;
            }
              @media (max-width: 480px) {
              background-size: 10%;
            }
            }
          }
        }


      }
    }

    &-map {
      padding: 29px 22px;
      background: $map-bg;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.17);

      @media (max-width: 1400px) {
        padding: 25px 20px;
        height: 450px;
      }
      @media (max-width: 768px) {
        height: 350px;
      }

      @media (max-width: 480px) {
          padding: 20px 15px;
        height: 400px;
        }

      #map {
        width: 100%;
        height: 100%;
        .ymaps-2-1-74-image {
          background-image: url("../images/logo.png")!important;
          background-size: cover;
        }
      }
    }

    &-address {
      padding: 24px 37px 30px;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.17);
      min-height: 512px;
      @media (max-width: 1400px) {
        padding: 25px 20px;
        height: 450px;
        min-height: auto;
      }
      @media (max-width: 768px) {
        height: 350px;
      }

      @media (max-width: 480px) {
          padding: 20px 15px;
                height: 400px;
        }

      &--tabs {
        display: flex;
        margin-bottom: 37px;
        padding: 0;
        @media (max-width: 1400px) {
          margin-bottom: 25px;
        }

        li {
          width: 50%;
          text-align: center;

          &:before{
          display: none!important;
        }
          &.active-address {
            a {
              color: $base-color;
              border-bottom: 2px solid $base-color;
            }
          }

          a {
            font-size: rem(25);
            line-height: rem(36);
            box-sizing: border-box;
            font-weight: 700;
            color: $gray;
            text-decoration: none;
            display: inline-block;
            @media (max-width: 1400px) {
              font-size: rem(20);
              line-height: rem(31);
            }
          }
        }
      }

      .address-desc {
        display: block;
      }

      &--description {
        max-width: 456px;
        display: none;

        p {
          font-size: rem(14);
          line-height: rem(23);
          color: $gray-dark;
          margin-bottom: 20px;
          @media (max-width: 1400px) {
            margin-bottom: 10px;
          }

          a {
            color: $gray-dark;
            text-decoration: none;
            display: block;
          }

          span {
            font-weight: 700;
          }
        }
      }
    }
  }

  .ymaps-2-1-74-islets_icon-with-caption {
    display: none;
  }

}