.cookie-policy {
  position: fixed;
  z-index: 10000;
  box-shadow: 0 0 35px rgba(0,0,0,.3);
  background: $white;
  width: 100%;
  max-width: 700px;
  bottom: 5%;
  right: 5%;
  @media (max-width: 768px) {
        max-width: 500px;
  }
  @media (max-width: 580px) {
     max-width: 100%;
    right: 0;
    bottom: 0;
  }
  .wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    @media (max-width: 1000px) {
        padding: 20px;
      }
  }

  &-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &-text{
    width: 100%;
    padding-right: 10px;
     @media (max-width: 768px) {
       width: 100%;
       margin-bottom: 10px;
      }
    p{
      @media (max-width: 1000px) {
        font-size: 10px;
      }
    }
  }
  &-button {
    @media (max-width: 768px) {
       align-self: flex-start;
      }
    @media (max-width: 580px) {
       align-self: center;
      }
    button {
      width: 100%;
      width: 100px;
      height: 40px;
      border: 0;
      outline: 0;
      background-color: $base-active-color;
      margin-top: 15px;
      @media (max-width: 1000px) {
        height: 30px;
        width: 80px;
        margin-top: 10px;
      }
      @media (max-width: 768px) {
       margin-top: 0;
      }
      span{
        color: $white;
        @media (max-width: 1000px) {
        font-size: 11px;
      }
      }
    }
  }
}