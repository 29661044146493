.furniture-choise{
    background: #E9EFF7;
    .wrapper{
        padding: 0.375rem 1.875rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
    a{
        font-size: 1.1875rem;
        font-weight: 500;
        color: #444159;
        display: block;
        text-decoration: none;
        padding: .8rem 2rem;
        border: 2px solid transparent;
        box-sizing: border-box;
        border-radius: 5px;
        margin: 0.625rem;
        white-space: nowrap;
        @extend .transition;
        &:hover{
            border-color: $link-color;
            color: $base-active-color;
        }
        &.active{
            border-color: $link-color;
            color: $base-active-color;
        }
    }
}

@media screen {
    @media (max-width: 1300px) {
        .furniture-choise {
            .wrapper {
                justify-content: space-between;
            }
            a {
                font-size: 1.1875rem;
                padding: .8rem 2rem;
            }
        }        
    }
    @media (max-width: 940px) {
        .furniture-choise {
            .wrapper {
                justify-content: center;
            }
            a {
                margin: 4px;
            }
        } 
    }
}