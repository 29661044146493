.delivery {
    .wrapper {
        overflow-x: hidden;
    }

    .container{
        position: relative;
        overflow: hidden;
    }
    h2 {
        font-weight: 500;
        font-size: rem(35);
        margin-top: rem(25);
    }
    h3 {
        font-size: rem(25);
    }
    &-form {
        width: 100%;
        max-width: 1839px;
        margin: 0 auto;
        margin-bottom: rem(68);
        margin-top: rem(25);
        padding: rem(64) rem(75) rem(59) rem(75);
        background: #FFFFFF;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);

        &__tabs {
            display: flex;
            margin-bottom: rem(115);
            .radio {
                &:not(:last-child) {
                    margin-right: ((376 * 100%) / 1689);
                }
            }
        }

        &__main {
            display: flex;
            justify-content: space-between;
            // flex-direction: column;
            // align-content: space-between;
            // flex-wrap: wrap;
            // max-height: rem(470);
        }

        &__price{
            display: flex;
            margin-top: rem(102);
            align-items: center;
            justify-content: space-between;
        }

        &__submit {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.column {
    width: 31%;
}

.contact-details {
    .checkbox {
        display: block;
        margin-top: rem(108);
    }
}

.tin {
    p{
        margin-top: rem(285);
        font-size: rem(20);
        color: $base-color;
    }
}

.delivery-city {
    .checkbox {
        display: block;
        margin-top: rem(45);
    }
}

.delivery-address {
    margin-top: rem(71);
}

.in-garbage {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 500;
    font-size: rem(30);
    line-height: normal;
    color: $base-color;
    @extend .transition;
    &__text {
        margin-left: 22px;
    }
    svg{
        width: 60px;
        height: 60px;
    }
    &:hover {
        color: #ffa2a2;
    }
}

.price-result {
    font-size: rem(22);
    color: $gray;
    margin-left: rem(22);
    &__price {
        font-size: rem(39);
        line-height: normal;
        color: $base-color;
        margin-right: rem(4);
    }
    &__currency {
        font-size: rem(25);
        line-height: normal;
        color: $base-color;
    }
}

.button-submit {
    font-size: 1.25rem;
    font-weight: 500;
    padding: .9rem 1.8rem;
    cursor: pointer;
    color: #ffffff;
    background: $base-active-color;
    border: 2px solid transparent;
    border-radius: 5px;
    margin-top: 1.5rem;
    @extend .transition;
    &:hover {
        background: transparent;
        border-color: #ffa2a2;
        color: $base-active-color;
    }
}

.passport-details{
    p{
        margin-top: rem(30);
    }
}

.checkbox {
    input {
        position: absolute;
        z-index: -1;
        opacity: 0;

        &:checked {
            & + .checkbox__text {
                color: $base-color;
            }
            & + .checkbox__text:before {
                background: $base-active-color;
                border: 1px solid $base-active-color;
            }
            & + .checkbox__text:after {
                opacity: 1;
            }
        }
    }
    
    &__text {
        position: relative;
        padding-left: 47px;
        font-size: 20px;
        line-height: normal;
        color: $gray;
        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
            border: 1px solid $light-dark;
            border-radius: 4px;
            background: $white;
            transition: .2s;
        }

        &:after {
            content: '';
            position: absolute;
            top: 2px;
            left: 9px;
            width: 7px;
            height: 15px;
            border-bottom: 2px solid $white;
            border-right: 2px solid $white; 
            transform: rotate(45deg);
            transition: .2s;
        }
    }
}

.radio {
    input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        margin: 10px 0 0 7px;
        &:checked {
            & + .radio__text {
                &:after {
                    background: $link-color;
                    opacity: 1;
                }
                &:before {
                    border: 2px solid $link-color;
                }
            }
        }
    } 
    &__text {
        position: relative;
        padding-left: 38px;
        cursor: pointer;
        font-size: rem(25);
        line-height: 25px;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 23px;
            height: 23px;
            border: 2px solid $base-color;
            border-radius: 50%;
            background: $white;
        }
        &:after {
            content: '';
            position: absolute;
            top: 5px;
            left: 5px;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background: $link-color;
            opacity: 0;
            transition: .2s;
        }
    }
}

.input {
    position: relative;
    padding-top: rem(20);   
    margin-top: rem(10);
    width: 100%;
    box-sizing: border-box;
    position: relative;
    padding-right: 1px;
    
    label {
        position: absolute;
        top: 0;
        left: 0;
        font-size: rem(15);
        opacity: 1;
        transform: translateY(0);
        transition: all 0.2s ease-out;
        z-index: 1;
    }

    input {
        box-sizing: border-box;
        font-size: rem(20);
        padding: rem(18) 0 rem(14) rem(22);
        // width: ((434 * 100%) / 1689);
        // width: rem(434);
        width: 100%;
        border: 0.75px solid $base-color;
        border-radius: rem(6);
        color: $base-color;
        z-index: 5;

        &::placeholder {
            color: $gray;
        }
    }
   
    input:placeholder-shown + label {
        opacity: 0;
        transform: translateY(1rem);
    }

    input:placeholder-shown {
        border: 0.75px solid $gray;
    }
}

.entity, .individual {
    position: absolute;
    top: 0;
    opacity: 0;
    min-width: calc(100vw - 7rem);
    max-width: 1920px;
    transition: .5s ease-in;
}

.individual {
    transform: translateX(-100vw);
}

.entity {
    transform: translateX(100vw);
}

.active {
    opacity: 1;
    position: static;
    display: block;
    transform: translateX(0);
    min-width: auto;
}

@media screen {

    @media (max-width: 1050px) {
        .tin{
            p{
                margin-top: rem(30);
            }
            h3{
                margin-top: 1.7rem;
            }
        }

        .delivery-form {
            &__price {
                margin-top: 3rem;
                flex-wrap: wrap;
            }
            &__main {
                flex-wrap: wrap;
            }
            &__tabs {
                margin-bottom: 2rem;
            }
            
        }
        .price-result {
            width: 100%;
            text-align: right;
        }
        .column {
            width: 100%;
        }
        .contact-details {
            .checkbox {
                margin: 1.7rem 0;
            }
        }
        .passport-details {
            p {
                margin: 1rem 0 1.7rem 0;
            }
        }
        .delivery-city {
            h3{
                margin-top: 1.7rem;
            }
            .checkbox {
                margin-top: 1.7rem;
            }
        }
        .delivery-address {
            margin-top: 1.7rem;
        }

        .in-garbage {
            font-size: 1.7rem;
            &__text {
                margin-left: 1rem;
            }
            svg {
                width: 34px;
                height: auto;
            }
        }
    }
    @media (max-width: 734px) {
        .delivery-form {
            padding: 2rem;
            &__tabs {
                .radio {
                    &:not(:last-child){
                        margin-right: 5%;
                    }
                }
            }
        }
        .price-result {
            &__price {
                display: block;
            }
        }
        .button-submit {
            font-size: 1.3rem;
            padding: 1rem 2rem;
        }
    }
    @media (max-width: 530px) {
        .delivery-form {
            &__tabs {
                flex-direction: column;

                .radio {
                    padding-bottom: 1rem;
                }
                .radio:not(:last-child) {
                    margin-right: 0;
                }
            }
        }
        .checkbox {
            &__text {
                font-size: 1.5rem;
                padding-left: 30px;
                padding-top: 1px;
            }
        }
    }
}