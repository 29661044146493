.footer{
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.136);
    .wrapper{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        flex-wrap: wrap;
        .logo-col{

            width: 220px;
            img{
                width: 100%;
            }
            .text{
                font-size: .75rem;
                line-height: 1.3125rem;
                font-weight: 300;
                margin-top: .575rem;
                letter-spacing: .1em;
            }
        }
        .footer-nav{
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            a{
                text-decoration: none;
                padding-bottom: 10px;

                font-weight: 400;
                font-size: 1.175rem;
                color: $base-color;
                transition: all .2s;
                &:first-child{
                    padding-left: 0;
                }
                &:hover{
                    color: $link-color--hover;
                    transition: all .2s;
                }
            }
        }
        .emails{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 30px;
            position: relative;
            .icon{
                position: absolute;
                top: 5px;
                left: 0;
                @include sprite ($email-icon);
            }
            a{
                text-decoration: none;
                font-weight: 400;
                font-size: 1.175rem;
                color: $base-color;
                margin-bottom: 0.875rem;
                @extend .transition;
                &:hover{
                    color: $link-color--hover;
                }
                &:active{
                    color: $link-color--active;
                }
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        .phone{
            display: flex;
            align-items: center;
            .icon{
                @include sprite($phone-icon);
                margin-right: 0.875rem;
            }
            a{
                text-decoration: none;
                font-size: 1.575rem;
                font-weight: 500;
                color: $base-color;
                @extend .transition;
                &:hover{
                    color: $link-color--hover;
                }
                &:active{
                    color: $link-color--active;
                }
            }
        }
        .coffee{
            font-size: 0.915rem;
            color: $base-color;
            @extend .transition;
            align-self: flex-end;
            width: 100%;
            text-align: right;
            a{
                text-decoration: none;
                color: $link-color;
                &:hover{
                    color: $link-color--hover;
                }
                &:active{
                    color: $link-color--active;
                }
            }
        }
        .container {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 75%;
            flex-direction: row;

        }
    }
    &-bottom{
        text-align: center;
        width: 100%;
        margin-top: 10px;
        span{
            font-size: 10px;
            color: #BFBFBF;
            a{
                text-decoration: none;
                color: lighten(#524F69, 25%);
                transition: all .2s;
                &:hover{
                    color: #524f69;
                    transition: all .2s;
                }
            }
        }
    }
}

@media screen {
    @media (max-width: 1200px) {
        .footer {
            .wrapper {
                padding: 1rem 2rem;
                flex-wrap: wrap;
                .coffee {
                    width: 100%;
                    text-align: end;
                    padding-top: 2rem;
                }
                .container {
                    width: calc(100% - 240px);
                }
            }
        }
    }
    @media (max-width: 800px) {
        .footer {
            .wrapper {
                .container{
                    .footer-nav{
                        justify-content: space-around;
                    }
                }
                .phone {
                    a {
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }
    @media (max-width: 740px) {
        .footer {
            .wrapper {
                .container {
                    width: calc(100% - 240px);
                    flex-direction: column;
                    align-items: center;
                }
                .phone {
                    margin-top: 1rem;
                }
            }
        }
    }
    @media (max-width: 530px) {
        .footer {
            .wrapper {
                flex-direction: column;
                align-items: center;
                .logo-col {
                    .text {
                        text-align: center;
                        margin-top: .5rem;
                    }
                }
                .container {
                    width: auto;
                    align-items: center;
                    padding: 1rem 0;
                }
                .coffee {
                    align-self: center;
                    width: auto;
                    margin: 0;
                    padding-top: .5rem;
                    font-size: 0.87rem;
                }
            }
        }
    }
}