.page-not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: rem(24) 0;
    h2 {
        font-size: rem(45);
        line-height: rem(53);
        color: #524F69;
    }
    &__svg {
        padding: rem(24) 0;
        overflow: visible;

        path {
            &:nth-child(1) {
                animation: 7s linear infinite motion-y;
            }
            &:nth-child(2) {
                animation: 6s linear infinite motion-x;
            }
            &:nth-child(3) {
                animation: 10s linear infinite motion-x;
            }
            &:nth-child(4) {
                animation: 5s linear infinite motion-x;
            }
            &:nth-child(5) {
                animation: 8s linear infinite motion-x;
            }
            &:nth-child(6) {
                animation: 10s linear infinite motion-y;
            }
            &:nth-child(7) {
                animation: 4s linear infinite motion-y;
            }
            &:nth-child(9) {
                animation: 7s linear infinite motion-y;
            }
            &:nth-child(12) {
                animation: 6s linear infinite motion-y;
            }
            &:nth-child(13) {
                animation: 8s linear infinite motion-y;
            }
        }
    }
}

.button-to-home {
    color: #FFFFFF;
    font-size: rem(30);
    padding: rem(26) rem(78);
    background: #FF8E8E;
    border-radius: rem(5);
    cursor: pointer;
    &:hover {
        background: #514A8F;
    }
}

@keyframes motion-x {
    0%, 100% {
        transform: translateX(30px);
    }
    50% {
        transform: translateX(-30px);
    }
}

@keyframes motion-y {
    0%, 100% {
        transform: translateY(30px);
    }
    50% {
        transform: translateY(-30px);
    }
}

@media screen {
    @media (max-width: 970px) {
        .page-not-found {
            h2 {
                font-size: 2rem;
            }
        }
        .button-to-home {
            padding: 1rem 4rem;
        }
    }
    @media (max-width: 610px) {
        .page-not-found {
            &__svg {
                width: 400px;
                height: auto;
            }
        }
    }
    @media (max-width: 450px) {
        .page-not-found {
            &__svg {
                width: 80%;
            }
        }
        .button-to-home {
            padding: 1rem 3rem;
            font-size: 1.5rem;
        }
    }
}