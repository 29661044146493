.header{
    border: 1px solid rgba(0, 0, 0, 0.136);
    .wrapper{
        padding-top: 1.3rem;
        padding-bottom: 1.3rem;
        display: flex;
        justify-content: space-between;
    }
    &-logo{
        width: 220px;
        &-img{
            width: 100%;
            margin-bottom: 0.575rem;
            max-width: 312px
        }
        &-text{
            width: 100%;
            font-weight: 300;
            font-size: 0.75rem;
            letter-spacing: 0.1em;
        }
    }
    &-right{
        width: calc(100% - 4.5rem - 320px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            .left{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .emails {
                    // display: flex;
                    position: relative;
                    align-items: center;
                    margin-right: 8.625rem;
                    .icon {
                        position: absolute;
                        left: 0;
                        top: 4px;
                        @include sprite($email-icon);
                        margin-right: 0.5rem;
                    }
                    .row {
                        padding-left: rem(30);
                    }
                    a{
                        font-size: 1.175rem;
                        color: $base-color;
                        text-decoration: none;
                        margin-right: 2.1875rem;

                        @extend .transition;
                        &:last-child{
                            margin-right: 0;
                        }
                        &:hover{
                            color: $link-color--hover;
                        }
                        &:active{
                            color: $link-color--active;
                        }
                    }
                }
                .phone{
                    display: flex;
                    align-items: center;
                    .icon{
                        @include sprite($phone-icon);
                        margin-right: 0.5rem;
                    }
                    a{
                        text-decoration: none;
                        font-size: 1.575rem;
                        font-weight: 500;
                        color: $base-color;
                        @extend .transition;
                        &:hover{
                            color: $link-color--hover;
                        }
                        &:active{
                            color: $link-color--active;
                        }
                    }
                }
            }
            .cart-block{
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-decoration: none;
                // width: 290px;
                padding: 0.9rem;
                border: 1px solid rgba(70, 67, 95, 0.116);
                box-sizing: border-box;
                border-radius: 5px;
                &:hover{
                    .icon-text{
                        .text{
                            color: $link-color--hover;
                        }
                    }
                }
                &:active{
                    .icon-text{
                        .text{
                            color: $link-color--active;
                        }
                    }
                }
                .icon-text{
                    display: flex;
                    align-items: center;
                    .icon{
                        @include sprite($cart-icon);
                        margin-right: 0.75rem;
                    }
                    .text{
                        font-size: 1.1rem;
                        color: $gray;
                        font-weight: 300;
                        @extend .transition;
                    }
                }
                .price{
                    font-size: 1.1875rem;
                    color: $base-color;
                    padding-left: 1rem;
                    span{
                        font-size: 1.6875rem;
                        font-weight: 500;
                        padding-right: 4px;
                    }
                }
            }
        }
        .bottom{
            display: flex;
            align-items: center;
            padding-left: 9.25rem;
            .factory{
                font-size: 1rem;
                color: #46435F;
                text-decoration: none;
                display: block;
                margin-right: 6.25rem;
                @extend .transition;
                &:hover{
                    color: $link-color--hover;
                }
                &:active{
                    color: $link-color--active;
                }
                &:last-child{
                    margin-right: 0;
                }
                &.active{
                    color: #ffffff;
                    background: $base-active-color;
                    border: 2px solid $base-active-color;
                    padding: 0.5rem 1.1rem;
                    border-radius: 5px;
                    @extend .transition;
                    &:hover{
                        background: transparent;
                        border: 2px solid $link-color;
                        color: $base-active-color;
                    }
                    &:active{
                        color: $link-color--active;
                    }
                }
            }
        }
    }
}

@media screen {
    @media (max-width: 1700px) {
        .header{
            &-logo{
                width: 220px;
                &-text{
                    font-size: .75rem;
                }
            }
            &-right {
                width: calc(100% - 320px);
                .top {
                    .cart-block {
                        padding: .8rem;
                    }
                    .left {
                        .phone {
                            a {
                                font-size: 1.5rem;
                                white-space: nowrap;
                            }
                        }
                    }
                }
                .bottom{
                    .factory{
                        &.active{
                            padding: .5rem 1.1rem;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1470px) {
        .header {
            &-right {
                .top {
                    .left{
                        .emails {
                            margin-right: 3rem;
                        }
                    }
                }
            } 
        }
    }

    @media (max-width: 1300px) {
        .header {
            &-right {
                .top {
                    .left{
                        .emails {
                            margin-right: 3rem;
                            .icon {
                                // width: 20px;
                                margin-right: 0;
                            }
                        }
                        .phone {
                            margin-right: 1rem;
                        }
                    }
                    .cart-block{
                        .price{
                            font-size: 1rem;
                            max-width: 120px;
                            white-space: nowrap;
                            span{
                                font-size: 1.4rem;
                            }
                        }
                    }
                }
                .bottom {
                    padding: 0;
                    justify-content: space-between;
                }
            } 
        }
    }

    @media (max-width: 1100px) {
        .header {
            &-right {
                .top {
                    .left{
                        .emails {
                            margin-right: 1rem;
                            a {
                                margin-right: 0;
                            }
                        }
                        .phone {
                            a {
                                font-size: 1.2rem;
                            }
                        }
                    }
                }
                .bottom {
                    .factory {
                        margin-right: 0;
                    }
                }
            } 
        }
    }
    @media (max-width: 970px) {
        .header {
            &-logo {
                width: 200px;
                &-text {
                    font-size: .8rem;
                }
            }
            &-right{
                width: calc(100% - 200px - 3rem);
                .top {
                    .cart-block {
                        padding: .3rem .8rem;
                        .icon-text {
                            .icon {
                                display: none;
                            }
                            .text {
                                font-size: 1.2rem;
                            }
                            .price {
                                span {
                                    font-size: 1.3rem;
                                }
                            }
                        }
                    }
                }   

                .bottom {
                    .factory {
                        &.active {
                            padding: .6rem 1rem;
                        }
                    }
                }            
            }
        }
    }

    @media (max-width: 840px) {
        .header {
            &-right {
                width: 100%;
                .top {
                    max-width: 550px;
                    margin: 1rem auto;
                }

                .bottom {
                    justify-content: center;
                    .factory {
                        margin-right: 5%;
                    }
                }
            }
            .wrapper {
                padding-top: 1rem;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    @media (max-width: 610px) {
        .header {
            &-right {
                .top {
                    justify-content: center;
                    .left {
                        flex-direction: column;
                        align-items: flex-start;
                        max-width: 220px;
                        .emails {
                            margin-right: 0;
                        }
                        .phone {
                            margin-top: .5rem;
                        }
                    }
                }
                .bottom {
                    flex-direction: column;
                    .factory {
                        &.active {
                            margin: 0;
                            margin-bottom: .5rem;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 430px) {
        .header {
            &-right {
                .top {
                    flex-direction: column;
                    .cart-block {
                        margin-top: .5rem;
                    }
                    .left {
                        max-width: 200px;
                    }
                }
            }
        }
    }
}