.product-item {
  width: calc(25% - 15px);
  margin-right: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  padding: 1.75rem;
  padding-bottom: 4.8em;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  flex-wrap: wrap;


  &.sale {
    .sale-size {
      display: block;
    }

    .price-block {
      .price {
        .old-price {
          display: block;
        }
      }
    }
  }

  .sale-size {
    position: absolute;
    right: 30px;
    top: 125px;
    width: 72px;
    height: 72px;
    background: rgba(122, 211, 255, 0.706);
    border-radius: 50%;
    color: #ffffff;
    text-align: center;
    line-height: 72px;
    font-size: 1.6875rem;
    font-weight: 500;
    display: none;
  }

  &:nth-child(4n) {
    margin-right: 0;
  }

  a {
    text-decoration: none;
    color: $base-color;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover {
      h3 {
        display: block;
        color: $link-color--hover;
      }
    }

    &:active {
      h3 {
        color: $link-color--active;
      }
    }

    h3 {
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 2rem;
      max-height: 96px;
      overflow: hidden;
      @extend .transition;

    }

    .img {
      width: 100%;
      height: auto;

      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
      }
    }
  }

  .price-block {
    width: 87%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    padding-bottom: 1.75rem;
    bottom: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    .price {
      font-size: 1.5rem;
      position: relative;
      top: -3px;

      .old-price {
        position: absolute;
        left: 0;
        bottom: 100%;
        width: 100%;
        text-align: center;
        font-size: 1.4375rem;
        color: #7A7A7A;
        display: none;

        &::after {
          content: '';
          width: 95%;
          height: 2px;
          background: #313131;
          position: absolute;
          top: calc(50% - 5px);
          left: 50%;
          transform: rotate(-4.24deg) translateX(-50%);
        }
      }

      span {
        font-weight: 500;
        font-size: 1.875rem;
      }
    }

    .cart-block {
      background: transparent;
      border: 1px solid rgba(70, 67, 95, 0.116);
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.75rem 0.875rem 0.75rem 1.25rem;
      cursor: pointer;

      &:hover {
        .text {
          color: $link-color--hover;
        }
      }

      &:active {
        .text {
          color: $link-color--active;
        }
      }

      .text {
        font-size: 1.25rem;
        font-weight: 30;
        color: #46435F;
        @extend .transition;
      }

      .icon {
        @include sprite($cart-icon);
        margin-left: 0.875rem;
      }
    }
  }

  .slider-wrapper {
    position: relative;
    width: 100%;
    padding: 40px 0 66px;
    margin: 8px 0 0;
    @media (max-width: 768px) {
      padding: 30px 0 46px;
    }

    .slide-content-top {
      position: absolute;
      padding: 8px 45px;
      background-color: #ff6316;
      color: $white;
      font-size: 17px;
      top: 0;
      right: 0;
      border-radius: 0 0 0 15px;
      @media (max-width: 768px) {
        font-size: 13px;
        padding: 5px 25px;
      }
    }

    .slide-content-bottom {
      position: absolute;
      padding: 8px 5px;
      font-size: 17px;
      width: 100%;
      max-width: 269px;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #ff6316;
      color: $white;
      text-align: center;
      z-index: 20;
      @media (max-width: 768px) {
        font-size: 13px;
        padding: 5px 5px;
      }
    }
  }

  &-slider {
    display: block;
    width: 100%;
    max-width: calc(100% - 62px);
    margin: 0 auto;
    align-self: flex-end;


    .img {
      height: 300px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      //position: relative;

      @media (max-width: 768px) {
        height: 250px;
      }

      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
      }


    }


    .slick-arrow {
      background: url(../images/sprite.png);
      border: 0;
      background-size: 72px 46px;
      font-size: 0;
      text-indent: -9999px;
      z-index: 1;
      position: absolute;
      top: calc(50% - 13px);
      width: 27px;
      height: 27px;
      opacity: .5;

      &.slick-prev {
        transform: rotate(180deg);
        left: -35px;
        top: calc(50% - 17px);
      }

      &.slick-next {
        right: -35px;
      }
    }
  }
}

.nav-slider {
  .slide-content-top,
  .slide-content-bottom {
    display: none;
    @media (max-width: 1040px) {
      display: block;
    }
  }
}

@media screen {
  @media (max-width: 1540px) {
    .product-item {
      a {
        h3 {
          font-size: 1.2rem;
        }
      }

      .price-block {
        .price {
          font-size: 1.2rem;
          top: -1px;

          span {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
  @media (max-width: 1416px) {
    .product-item {
      width: calc(33% - 15px);

      &:nth-child(4n) {
        margin-right: 20px;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  @media (max-width: 1085px) {
    .product-item {
      width: calc(50% - 15px);

      &:nth-child(3n) {
        margin-right: 20px;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  @media (max-width: 700px) {
    .wrapper {
      padding: 0 1rem;
    }
    .product-item {
      width: 49%;
      margin-right: 2%;

      &:nth-child(3n) {
        margin-right: 2%;
      }

      .price-block {
        .cart-block {
          padding: 0.3rem 0.5rem 0.3rem 0.5rem;
        }

        .price {
          font-size: 1rem;

          span {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
  @media (max-width: 550px) {
    .product-item {
      padding: 8px 8px 70px 8px;
      margin-bottom: 8px;

      &:nth-child(3n) {
        margin-right: 2%;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }

      a h3 {
        font-size: 14px;
        line-height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        box-orient: vertical;
      }

      &-slider {
        max-width: calc(100% - 24px);

        .img {
          height: 140px;
        }

        .slick-arrow {
          &.slick-prev {
            transform: rotate(180deg) scale(.75);
            left: -20px;
          }

          &.slick-next {
            transform: scale(.75);
            right: -20px;
          }
        }
      }

      .price-block {
        display: block;
        padding-bottom: 8px;
        width: calc(100% - 16px);

        .cart-block {
          width: 100%;
          margin: 4px 0 0;
        }
      }
    }
  }
  @media (max-width: 430px) {
    .product-item {
      &-slider {
        .img {
          height: 100px;

          a {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}