// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$cart-icon-name: 'cart-icon';
$cart-icon-x: 30px;
$cart-icon-y: 36px;
$cart-icon-offset-x: -30px;
$cart-icon-offset-y: -36px;
$cart-icon-width: 23px;
$cart-icon-height: 23px;
$cart-icon-total-width: 102px;
$cart-icon-total-height: 61px;
$cart-icon-image: '/images/sprite.png';
$cart-icon: (30px, 36px, -30px, -36px, 23px, 23px, 102px, 61px, '../images/sprite.png', 'cart-icon', );
$delete-name: 'delete';
$delete-x: 0px;
$delete-y: 36px;
$delete-offset-x: 0px;
$delete-offset-y: -36px;
$delete-width: 25px;
$delete-height: 25px;
$delete-total-width: 102px;
$delete-total-height: 61px;
$delete-image: '/images/sprite.png';
$delete: (0px, 36px, 0px, -36px, 25px, 25px, 102px, 61px, '../images/sprite.png', 'delete', );
$email-icon-name: 'email-icon';
$email-icon-x: 81px;
$email-icon-y: 25px;
$email-icon-offset-x: -81px;
$email-icon-offset-y: -25px;
$email-icon-width: 19px;
$email-icon-height: 19px;
$email-icon-total-width: 102px;
$email-icon-total-height: 61px;
$email-icon-image: '/images/sprite.png';
$email-icon: (81px, 25px, -81px, -25px, 19px, 19px, 102px, 61px, '../images/sprite.png', 'email-icon', );
$phone-icon-name: 'phone-icon';
$phone-icon-x: 81px;
$phone-icon-y: 0px;
$phone-icon-offset-x: -81px;
$phone-icon-offset-y: 0px;
$phone-icon-width: 21px;
$phone-icon-height: 20px;
$phone-icon-total-width: 102px;
$phone-icon-total-height: 61px;
$phone-icon-image: '/images/sprite.png';
$phone-icon: (81px, 0px, -81px, 0px, 21px, 20px, 102px, 61px, '../images/sprite.png', 'phone-icon', );
$slick-arrow-name: 'slick-arrow';
$slick-arrow-x: 0px;
$slick-arrow-y: 0px;
$slick-arrow-offset-x: 0px;
$slick-arrow-offset-y: 0px;
$slick-arrow-width: 38px;
$slick-arrow-height: 31px;
$slick-arrow-total-width: 102px;
$slick-arrow-total-height: 61px;
$slick-arrow-image: '/images/sprite.png';
$slick-arrow: (0px, 0px, 0px, 0px, 38px, 31px, 102px, 61px, '../images/sprite.png', 'slick-arrow', );
$to-catalog-name: 'to-catalog';
$to-catalog-x: 43px;
$to-catalog-y: 0px;
$to-catalog-offset-x: -43px;
$to-catalog-offset-y: 0px;
$to-catalog-width: 33px;
$to-catalog-height: 27px;
$to-catalog-total-width: 102px;
$to-catalog-total-height: 61px;
$to-catalog-image: '/images/sprite.png';
$to-catalog: (43px, 0px, -43px, 0px, 33px, 27px, 102px, 61px, '../images/sprite.png', 'to-catalog', );
$spritesheet-width: 102px;
$spritesheet-height: 61px;
$spritesheet-image: '/images/sprite.png';
$spritesheet-sprites: ($cart-icon, $delete, $email-icon, $phone-icon, $slick-arrow, $to-catalog, );
$spritesheet: (102px, 61px, '/images/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
