.cart-item{
    padding-left: 3.875rem;
    display: flex;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    position: relative;
    margin-bottom: 1.5rem;
    .img{
        width: 260px;
        margin-right: 6.875rem;
        img{
            width: 100%;
        }
    }
    .info{
        display: flex;
        align-items: flex-start;
        a{
            padding-right: 4rem;
            display: block;
            width: 450px;
            margin-right: 8.4375rem;
            font-size: 1.6rem;
            line-height: 2.875rem;
            font-weight: 500;
            color: $base-color;
            text-decoration: none;
            @extend .transition;
            &:hover{
                color: $link-color--hover;
            }
            &:active{
                color: $link-color--active;
            }
        }
        .title{
            font-size: 1.375rem;
            color: #BFBFBF;
            margin-bottom: 1.25rem;
        }
        .count{
            margin-right: 15rem;
            .jq-number{
                width: 132px;
                height: 36px;
                position: relative;
                padding: 0 36px;
                box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.062);
                margin-top: 1rem;
                &__field{
                    width: 100%;
                    height: 100%;
                    background: #ffffff;
                    input{
                        width: 100%;
                        height: 100%;
                        display: block;
                        text-align: center;
                        border: 0;
                        padding: 0;
                        background: #ffffff;
                        font-size: 1.3125rem;
                        color: #636363;
                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        }
                    }
                }
                &__spin{
                    width: 36px;
                    height: 36px;
                    background: #ffffff;
                    position: absolute;
                    top: 0;
                    cursor: pointer;
                    &:hover{
                        &::before{
                            opacity: 1;
                        }
                    }
                    &::before{
                        content: '';
                        width: 14px;
                        height: 1px;
                        background: #000000;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        opacity: .7;
                        @extend .transition;
                    }
                    &.plus{
                        right: 0;
                        &:hover{
                            &::after{
                                opacity: 1;
                            }
                        }
                        &::after{
                            content: '';
                            height: 14px;
                            width: 1px;
                            background: #000000;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            opacity: .7;
                            @extend .transition;
                        }
                    }
                    &.minus{
                        left: 0;
                    }
                }
            }
        }
        .price-block{
            .price{
                margin-top: 1rem;
                font-size: 1.5rem;
                color: $link-color;
                span{
                    font-weight: 500;
                    font-size: 2.225rem;
                    padding-right: 5px;
                }
            }
        }
    }
    .delete{
        position: absolute;
        top: 3rem;
        right: 2.25rem;
        background: transparent;
        border: 0;
        cursor: pointer;
        @include sprite($delete);
    }
}