.product-page {
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-top: 1.625rem;
    padding-bottom: 3.75rem;

    h1 {
      width: 100%;
      text-align: center;
      margin-bottom: 2.5625rem;
    }

    .sliders-container {
      width: 40%;
      background: #ffffff;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
      padding-bottom: 1.25rem;

      @media (max-width: 1040px) {
        position: relative;
        padding-bottom: 3rem;
      }


      .big-slider-wrapper {
        position: relative;
        padding: 2.5rem 0 55px;
        margin: 0;
        @media (max-width: 1040px) {
          position: static;
          padding: 0;
        }


        .slide-content-top {
          position: absolute;
          padding: 8px 45px;
          background-color: #ff6316;
          color: $white;
          font-size: 19px;
          top: 0;
          right: 0;
          border-radius: 0 0 0 15px;
          z-index: 20;
          @media (max-width: 1024px) {
            padding: 5px 30px;
            font-size: 15px;
          }
          @media (max-width: 375px) {
            font-size: 13px;
          }
        }

        .slide-content-bottom {
          position: absolute;
          padding: 8px 20px;
          font-size: 19px;
          bottom: 0;
          width: 100%;
          max-width: 380px;
          background-color: #ff6316;
          color: $white;
          text-align: center;
          z-index: 20;
          left: 50%;
          transform: translateX(-50%);
          @media (max-width: 1040px) {

            max-width: 612px;
          }
          @media (max-width: 1024px) {
            padding: 5px 20px;
            font-size: 15px;
          }
          @media (max-width: 375px) {
            font-size: 13px;
            padding: 5px 10px;
          }
        }
      }

      .big-slider {
        width: 100%;
        padding: 3.5rem 8.875rem 0;

        .slick-arrow {
          background: transparent;
          border: 0;
          font-size: 0;
          text-indent: -9999px;
          z-index: 1;
          position: absolute;
          top: calc(50% - 15px);
          @extend .transition;
          @include sprite($slick-arrow);
          opacity: 0.5;

          &:hover {
            opacity: 1;
          }

          &.slick-prev {
            left: 40px;
            transform: rotate(180deg);
          }

          &.slick-next {
            right: 40px;
          }
        }
      }

      .nav-slider {
        width: 100%;
        padding: 2.5rem 7.875rem 0;

        .slick-slide {
          padding: 0 5px;

          &:has(div .slide-content-bottom),
          &:has(div .slide-content-top) {
            display: none;
          }

          .slide {
            border: 1px solid transparent;
          }

          &.slick-current {
            .slide {
              border-color: $link-color;
            }
          }
        }
      }

      .slide {

        img {
          width: 100%;
          height: auto;
        }


      }
    }

    .info {
      width: calc(60% - 4.875rem);

      .modules-price, .kit-price {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        border-bottom: 1px solid rgba(0, 0, 0, 0.108);

      }

      .title {
        color: $gray;
        font-size: 1.375rem;
        margin-top: 15px;
        margin-right: 5px;
      }

      .modules-price {
        margin-bottom: 2rem;

        .price {
          font-size: 1.2625rem;
          color: $base-color;

          span {
            font-weight: 500;
            font-size: 1.8125rem;
          }
        }
      }

      .kit-price {
        margin-bottom: 1.5rem;

        .price {
          font-size: 1.3625rem;
          color: $link-color;

          span {
            font-weight: 500;
            font-size: 2.225rem;
          }
        }
      }

      .description {
        padding-bottom: 3.25rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.108);

        .title {
          width: 100%;
        }

        p {
          font-size: 1.375rem;
          color: #4f4f4f;
          margin-top: 0.5rem;
        }

        strong {
          font-weight: 700;
        }

        em {
          font-style: italic;
        }
      }

      .buy {
        width: 100%;
        padding-top: 3rem;

        form {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .price-block {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;

          .count {
            .jq-number {
              width: 132px;
              height: 36px;
              position: relative;
              padding: 0 36px;
              box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.062);
              margin-top: 1rem;

              &__field {
                width: 100%;
                height: 100%;
                background: #ffffff;

                input {
                  width: 100%;
                  height: 100%;
                  display: block;
                  text-align: center;
                  border: 0;
                  padding: 0;
                  background: #ffffff;
                  font-size: 1.3125rem;
                  color: #636363;

                  &::-webkit-outer-spin-button,
                  &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    margin: 0;
                  }
                }

                input[type="number"] {
                  -moz-appearance: textfield;
                }

                input[type="number"]:hover,
                input[type="number"]:focus {
                  -moz-appearance: textfield;
                }
              }

              &__spin {
                width: 36px;
                height: 36px;
                background: #ffffff;
                position: absolute;
                top: 0;
                cursor: pointer;

                &:hover {
                  &::before {
                    opacity: 1;
                  }
                }

                &::before {
                  content: '';
                  width: 14px;
                  height: 1px;
                  background: #000000;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  opacity: .7;
                  @extend .transition;
                }

                &.plus {
                  right: 0;

                  &:hover {
                    &::after {
                      opacity: 1;
                    }
                  }

                  &::after {
                    content: '';
                    height: 14px;
                    width: 1px;
                    background: #000000;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    opacity: .7;
                    @extend .transition;
                  }
                }

                &.minus {
                  left: 0;
                }
              }
            }
          }

          .price-block {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 4.5rem;

            .price {
              font-size: 1.375rem;
              color: #BFBFBF;
              margin-top: 1.25rem;
            }
          }
        }

        button {
          font-size: 1rem;
          color: #ffffff;
          font-weight: 500;
          background: $base-active-color;
          border-radius: 5px;
          border: 2px solid transparent;
          @extend .transition;
          padding: .8rem 2rem;

          &:hover {
            color: $base-active-color;
            border-color: $link-color--hover;
            background: transparent;
          }

          &:active {
            color: $link-color--active;
          }
        }

      }

    }

  }
}

@media screen {
  @media (max-width: 1300px) {
    .product-page {
      .wrapper {
        .sliders-container {
          width: 50%;
        }

        .info {
          width: calc(50% - 4.875rem);

          .description {
            padding-bottom: 2rem;

            p {
              font-size: 1rem;
            }
          }

          .buy {
            padding-top: 1rem;

            button {
              font-size: 1.2rem;
              padding: 1rem 2rem;
            }

            .price-block {
              .price-block {
                margin-left: 1rem;
              }
            }
          }

          .kit-price {
            .price {
              span {
                font-size: 2rem;
              }
            }
          }

          .modules-price {
            margin-bottom: 1rem;

            .price {
              span {
                font-size: 2rem;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1220px) {
    .product-page {
      .wrapper {
        .info {
          .modules-price {
            display: flex;
            flex-direction: column;
            margin-bottom: 0;
          }

          .kit-price {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
  @media (max-width: 1040px) {
    .product-page {
      .wrapper {
        flex-direction: column;

        .sliders-container {
          width: 100%;

          .big-slider {
            display: none;
          }

          .nav-slider {
            padding: 0 2rem 0 2rem;
            @media (max-width: 1024px) {
              padding-top: 40px;
            }
          }
        }

        .info {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 460px) {
    .product-page {
      .wrapper {
        padding-bottom: 1.75rem;

        .info {
          .buy {
            form {
              flex-wrap: wrap;
            }

            .price-block {
              width: 100%;
            }

            button {
              margin-left: auto;
              margin-top: 1rem;
            }

            .price-block {
              .price-block {
                margin-left: 10%;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 370px) {
    .product-page {
      .wrapper {
        .info {
          .buy {
            button {
              margin: 0 auto;
              margin-top: 2rem;
            }
          }
        }
      }
    }
  }
}

.scheme {
  display: none;
}