.payment {
    &-form {
        background: #FFFFFF;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
        padding: rem(81) rem(97) rem(40) rem(37);
        margin-bottom: rem(68);
        margin-top: rem(25);
    }
    .row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .button-submit{
            margin-top: 0;
        }

        .checkbox {
            width: 30%;
            &__text {
                a {
                    text-decoration: none;
                    color: #514A8F;
                    &:hover {
                        color: #FF8888;
                    }
                }
            }
        }

        &:not(:nth-child(1)) {
            margin-top: rem(45);
        }

        &:nth-child(2) {
            align-items: flex-end;
        }

        &:last-child {
            justify-content: flex-end;
        }

    }
    .radio {
        display: flex;
        align-items: center;
        svg {
            margin-right: 1rem;
        }
    }

    h2 {
        color: #524F69;
        font-weight: 500;
        font-size: rem(35);
        margin-top: rem(25);
        // margin-bottom: rem(34);
    }

    .payment {
        &-price {
            min-width: 28%;
        }
        &-total {
            font-style: normal;
            font-weight: 500;
            font-size: rem(28);
            line-height: rem(61);
            color: #524F69;

            &__price {
                font-weight: 500;
                font-size: rem(40);
                line-height: rem(59);
                color: #FF8888;
            }
            &__currency {
                font-size: rem(25);
                line-height: rem(29);
                color: #FF8888;
            }
        }
        &-order {
            display: flex;
            justify-content: space-between;

            &__text {
                font-size: rem(22);
                line-height: rem(32);
                color: #BFBFBF;
            }
            &__price {
                color: #524F69;
                font-size: rem(39);
                line-height: rem(46);
            }
            &__currency {
                font-size: 25px;
                line-height: 29px;
                color: #524F69;
            }

            &-container {
                margin-left: 1rem;
            }

            &:nth-child(1) {
                .payment-order__text {
                    align-self: end;
                }
            }

            &:nth-child(2) {
                margin-top: rem(55);
                .payment-order-container {
                    align-self: end;
                }
            }
        }
    }
}

.edit-order {
    &__text {
        font-weight: 500;
        font-size: rem(28);
        line-height: rem(41);
        color: #514A8F;
        &:hover {
            color: #FF8888;
        }
    }
}

@media screen {
    @media (max-width: 1450px) {
        .payment {
            .row {
                &:nth-child(2) {
                    flex-wrap: wrap;
                    .checkbox {
                        order: 1;
                        width: 50%;
                    }
                    .edit-order {
                        order: 3;
                        width: 100%;
                        margin-top: 1rem;
                    }
                    .payment-total {
                        order: 2;
                    }
                }
                &:nth-child(3) {
                    margin-top: 0;
                }
            } 
        }
    }
    @media (max-width: 1240px) {
        .payment {
            &-form {
                padding: 2rem;
            }
            .row {
                &:nth-child(1) {
                    flex-wrap: wrap;
                }
            }
            .payment-price {
                width: 100%;
            }
            .radio {
                &:nth-child(2) {
                    margin-right: auto;
                    margin-left: 5%;
                }
            }
            .payment-order {
                justify-content: end;
                &:nth-child(2) {
                    margin-top: 1rem;
                }
            }
        }
    }
    @media (max-width: 1030px) {
        .payment {
            .row {
                &:nth-child(2) {
                    .payment-total {
                        order: 0;
                        margin-left: auto;
                    }
                    .checkbox {
                        width: 100%;
                        margin: 1rem 0;
                    }
                }
            } 
        }
    }
    @media (max-width: 740px) {
        .payment {
            .row{
                &:nth-child(1) {
                    flex-direction: column;
                    .radio {
                        &:nth-child(2) {
                            svg {
                                margin-right: 1.35rem;
                            }
                        }
                    }
                }
                &:nth-child(2) {
                    margin-top: 1rem;
                }
                &:last-child {
                    margin-top: 1rem;
                }
            }
            .radio {
                &:nth-child(2) {
                    margin-right: 0;
                    margin-left: 0;
                }
            }
            .payment {
                &-order {
                    &__price {
                        font-size: 1.7rem;
                        line-height: normal;
                    }
                    &__currency {
                        font-size: 1rem;
                        line-height: normal;
                    }
                }
                &-total {
                    font-size: 2rem;
                    &__price {
                        font-size: 2.5rem;
                    }
                }
            }
        }
        .edit-order {
            &__text {
                font-size: 1.6rem;
            }
        }
    }

    @media (max-width: 560px) {
        .payment {
            .row {
                &:nth-child(2) {
                    .payment-total {
                        margin-left: 0;
                    }
                }
            }
            .payment {
                &-order {
                    margin-top: 1rem;
                    justify-content: flex-start;
                }
            }
        }
    }
    @media (max-width: 430px) {
        .payment {
            .radio {
                &:first-child {
                    margin-bottom: .5rem;
                }
                svg {
                    display: none;
                }
            }
            .payment {
                &-order {
                    flex-direction: column;
                    &:nth-child(1) {
                        .payment-order__text {
                            align-self: start;
                        }
                    }
                    &:nth-child(2) {
                        .payment-order-container {
                            align-self: start;
                        }
                    }
                    &-container {
                        margin-left: 0;
                    }
                    &__text {
                        br {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}