/* roboto-300 - latin_cyrillic-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/roboto-v19-latin_cyrillic-ext-300.eot'); /* IE9 Compat Modes */
    src: local('Roboto Light'), local('Roboto-Light'),
         url('../fonts/roboto-v19-latin_cyrillic-ext-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v19-latin_cyrillic-ext-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v19-latin_cyrillic-ext-300.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v19-latin_cyrillic-ext-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v19-latin_cyrillic-ext-300.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-regular - latin_cyrillic-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/roboto-v19-latin_cyrillic-ext-regular.eot'); /* IE9 Compat Modes */
    src: local('Roboto'), local('Roboto-Regular'),
         url('../fonts/roboto-v19-latin_cyrillic-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v19-latin_cyrillic-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v19-latin_cyrillic-ext-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v19-latin_cyrillic-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v19-latin_cyrillic-ext-regular.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-500 - latin_cyrillic-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/roboto-v19-latin_cyrillic-ext-500.eot'); /* IE9 Compat Modes */
    src: local('Roboto Medium'), local('Roboto-Medium'),
         url('../fonts/roboto-v19-latin_cyrillic-ext-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v19-latin_cyrillic-ext-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v19-latin_cyrillic-ext-500.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v19-latin_cyrillic-ext-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v19-latin_cyrillic-ext-500.svg#Roboto') format('svg'); /* Legacy iOS */
  }