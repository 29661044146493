.approve {
  position: relative;
  display: block;
  margin-bottom: 25px;
  padding: 20px;
  padding-left: 80px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.086);
  font-family: $font-family;
  font-weight: 500;
  color: $gray-dark;
  @media screen {
    @media (max-width: 460px) {
      padding-left: 20px;
      padding-top: 70px;
      text-align: center;
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 25px;
    left: 20px;
    width: 40px;
    height: 40px;
    border: 1px solid #32BA7C;
    border-radius: 50%;
    background: url("../images/approve.svg") center no-repeat;
    background-size: 65%;
    @media screen {
      @media (max-width: 460px) {
          top: 20px;
          left: calc(50% - 20px);
          width: 40px;
          height: 40px;
      }
    }
  }
  &__header {
    font-size: 20px;
    color: #32BA7C;
    @media screen {
      @media (max-width: 800px) {
        font-size: 18px;
      }
      @media (max-width: 600px) {
        font-size: 16px;
      }
      @media (max-width: 460px) {
        margin-bottom: 10px;
      }
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 400;
    span {
      display: inline-block;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &--bold {
      font-size: 18px;
      font-weight: 600;
      @media screen {
        @media (max-width: 800px) {
          font-size: 16px;
        }
        @media (max-width: 600px) {
          font-size: 14px;
        }
      }
    }
    @media screen {
      @media (max-width: 800px) {
        font-size: 14px;
      }
      @media (max-width: 460px) {
        padding: 0 20px;
      }
      @media (max-width: 350px) {
        padding: 0;
      }
    }
  }
}