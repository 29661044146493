.cart-page{
    .wrapper{
        padding-top: 1.525rem;
        padding-bottom: 2.5rem;
    }
    h1{
        text-align: left;
        margin-bottom: 1.5rem;
    }
    .bottom{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .to-catalog{
            display: flex;
            align-items: center;
            text-decoration: none;
            margin-top: 1.875rem;
            &:hover{
                .text{
                    color: $link-color--hover;
                }
            }
            &:active{
                .text{
                    color: $link-color--active;
                }
            }
            .icon{
                @include sprite($to-catalog);
                margin-right: 2.25rem;
            }
            .text{
                font-size: 1.9rem;
                font-weight: 500;
                color: $base-active-color;
                @extend .transition;
            }
        }
        .total{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .item{
                display: flex;
                align-items: flex-end;
                line-height: 2rem;
                font-size: 1.375rem;
                .name{
                    margin-right: 0.5rem;
                }
            }
            .total-summ{
                margin-top: 1.25rem;
                display: flex;
                align-items: flex-end;
                .text{
                    font-size: 1.5rem;
                    font-weight: 500;
                    color: $base-color;
                    margin-right: 1.5rem;
                }
                .price{
                    font-size: 1.2625rem;
                    color: $link-color;
                    span{
                        font-size: 2.1rem;
                        font-weight: 500;
                        line-height: 2rem;
                    }
                }
            }
            button{
                font-size: 1.25rem;
                font-weight: 500;
                padding: 0.9rem 1.8rem;
                cursor: pointer;
                color: #ffffff;
                background: $base-active-color;
                border: 2px solid transparent;
                border-radius: 5px;
                @extend .transition;
                margin-top: 1.5rem;
                &:hover{
                    background: transparent;
                    border-color: $link-color--hover;
                    color: $base-active-color;
                }
                &:active{
                    color: $link-color--active;
                }
            }
        }
    }
}

.cart-item .info .count .jq-number__field input {
    appearance: textfield;
}

@media screen {
    @media (max-width: 1740px) {
        .cart-item {
            padding: 0 2%;
            .img {
                margin-right: 0;
            }
            .info {
                width: calc(97% - 260px);
                margin-left: 3%;
                .count {
                    margin: 0 auto;
                    padding: 0 1rem;
                }
                a {
                    font-size: 1.7rem;
                    line-height: 1.7;
                    margin-right: 0;
                }
                .price-block {
                    .price {
                        
                    }
                }
            }
        }
    }

    @media (max-width: 1300px) {
        .cart-item {
            .info {
                a{
                    font-size: 1.5rem;
                }
                .price-block {
                    .price {
                        span {
                            font-size: 2rem;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1180px) {
        .cart-item {
            .info {
                flex-wrap: wrap;
                margin: 5% 0;
                margin-left: 5%;
                .count {
                    margin: 0;
                    padding-left: 0;
                }
                a {
                    width: 100%;
                    margin-bottom: 1rem;
                }
            }
        }
    }

    @media (max-width: 800px) {
        .cart-item {
            .img {
                width: 170px;
            }
            .delete {
                top: 1rem;
                right: 1rem;
                transform: scale(0.5);
            }
            .info {
                width: calc(97% - 215px);
                a {
                    font-size: 1.4rem;
                }
                .title {
                    font-size: 1.1rem;
                    margin-bottom: .5rem;
                }
                .count {
                    .jq-number {
                        width: 105px;
                        margin-top: .5rem;
                    }
                }
            }
        }
    }

    @media (max-width: 640px) {
        .cart-page {
            .bottom {
                .to-catalog {
                    .text {
                        font-size: 1.5rem;
                    }
                }
                .total {
                    button {
                        font-size: 1.4rem;
                        padding: .9rem 2rem;
                    }
                    .total-summ {
                        margin-top: .8rem;
                        .text {
                            font-size: 1.8rem;
                        }
                        .price {
                            span {
                                font-size: 2rem;
                            }
                        }
                    }
                }
            }
        }
        .cart-item {
            .img {
                width: 115px;
            }
            .info {
                width: calc(97% - 120px);
                margin-top: 2rem;
            }
            .delete {
                top: .4rem;
            }
        }
    }

    @media (max-width: 540px) {
        .cart-page {
            .bottom {
                flex-direction: column;
                .total {
                    width: 100%;
                    align-items: flex-start;
                    margin-top: 2rem;
                    button {
                        margin: 0 auto;
                        margin-top: 1.8rem;
                    }
                }
            }
        }
        .cart-item {
            flex-direction: column;
            .info {
                width: 100%;
                margin-top: 0;
            }
        }
    }
}