* {
  &::before, &::after {
    box-sizing: inherit;
  }
}

html {
  font-family: $font-family;
  font-size: $font-size;
  color: $base-color;
  box-sizing: border-box;
  height: 100%;
  @media screen {
    @media (max-width: 1700px) {
      font-size: 15px;
    }
    @media (max-width: 1370px) {
      font-size: 14px;
    }
    @media (max-width: 1100px) {
      font-size: 13px;
    }
    @media (max-width: 340px) {
      font-size: 12px;
    }
  }
}

body {
  background: $body-bg-color;
  font-size: 1rem;
  line-height: normal;
  height: 100%;

  &.off-scroll {
    overflow: hidden;
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;

  & > .content {
    flex: 1 0 auto;
    position: relative;
  }
}

.wrapper {
  margin: 0 auto;
  padding: map-get($wrapper, 'padding');
  max-width: map-get($wrapper, 'max-width');
  min-width: map-get($wrapper, 'min-width');
}

.transition {
  transition: .2s;
}

h1 {
  font-size: 2.1875rem;
  font-weight: 500;
  line-height: normal;
}

h2 {
  font-size: 1.5rem;
  font-weight: 300;
}

h3 {
  font-size: 1.4375rem;
  font-weight: 500;
}

.counters {
  visibility: hidden;
  position: absolute;
  z-index: -99999;
}

